import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class TabStorageService {
  private tabId: string;
  public userRoles: string[];
  public adminUser: boolean = false;
  constructor() {
    this.initializeTabStorage();    
  }

  private initializeTabStorage() {
    // Check if sessionStorage already has a unique identifier for this tab
    if (!sessionStorage.getItem('tabId')) {
      // Generate a unique identifier for this tab
      this.tabId = this.generateUniqueIdentifier();
      sessionStorage.setItem('tabId', this.tabId);
    }
    // Retrieve the unique identifier for this tab
    this.tabId = sessionStorage.getItem('tabId');

  }
  getTabId() {
    return this.tabId;
  }
  private generateUniqueIdentifier(): string {
    return (
      Math.random().toString(36).substring(2) + Date.now().toString(36)
    );
  }
}
