import { Component, OnInit } from '@angular/core';
import { ITableRow } from '../../../../common/models/components/iTableRow';
import { ITableHeader } from '../../../../common/models/components/iTableHeader';
import { VTableService } from '../../../../service/tcdb/vtable.service';
import { ClassData, vTable } from '../../../../common/models/vTable.model';
import { AlertErrorService } from '../../../../service/tcdb/alert-error.service';
import { MOD_UID } from '../../components/upload-utility/upload-utility-helper';

@Component({
  selector: 'app-tableeditor',
  templateUrl: './tableeditor.component.html',
  styleUrls: ['./tableeditor.component.css'],
  providers: [VTableService]
})

export class TableeditorComponent implements OnInit {
  parameterlist: any[];
  tableHeader: ITableHeader[] = [];
  tableData: ITableRow[] = [];
  useAddRow = true;
  showTable = false;
  selecteditem: string;
  isDataModified: boolean = false;
  ConfirmationModalOpen: boolean = false;
  vtabledatakey: string = 'vTableData';
  deleteItem: any;
  constructor(public _vtableService: VTableService, private _alertErrorService: AlertErrorService) {
    this.getTableDataList();
  }

  ngOnInit(): void {
    this.createTableHeader();
    this.useAddRow = true;
  }

  createTableHeader() {
    this.tableHeader.push({ name: `Value`, isNumeric: false, errorMessage: '' });
    this.tableHeader.push({ name: `CHNG_DATE`, isNumeric: false, errorMessage: '' });
    this.tableHeader.push({ name: `MOD_UID`, isNumeric: false, errorMessage: '' });
  }

  async deleteTableData(index: any) {
    this.ConfirmationModalOpen = true;
    this.deleteItem = index;
  }

  changedata(event: any) {
    this.selecteditem = event.target.value;
    this.getTableData(this.selecteditem);
    this.showTable = true;
  }

  getTableDataList() {
    this._vtableService.getTableList().subscribe(x => {
      this.parameterlist = x.data.filter(x => x != 'TST_FCLT_VTBL' && x != 'PHYS_EVAL_TEST_TYPE_VTBL');
    });
  }

  getTableData(item: string) {
    this._vtableService.getTableData(item).subscribe(x => {
      sessionStorage.setItem(this.vtabledatakey, JSON.stringify(x));
      this.populatedata(x);
    });
  }

  addEventCall() {
    this.isDataModified = true;
  }

  closeModal() {
    this.ConfirmationModalOpen = false;
  }

  deleterecord() {
    this.ConfirmationModalOpen = false;
    var itemname = [...this.tableData].splice(this.deleteItem, 1);
    this._vtableService.deleteItem(this.selecteditem, itemname[0].tdColumn[0].value).subscribe(x => {
      this.populatedata(x);
    });
  }

  populatedata(dataResponse:any) {
    if (dataResponse.data !== null) {
      this.tableData = [];
      this.tableHeader = [];
      this.createTableHeader();
      if (dataResponse.data.length == 0) return;

      //check if the table data contains 4 columns
      if (dataResponse.data[0]["col2Name"] !== null) {
        this.tableHeader[1].name = dataResponse.data[0]["col2Name"];
        this.tableHeader[2].name = 'CHNG_DATE';
        this.tableHeader.push({ name: `MOD_UID`, isNumeric: false, errorMessage: '' });

        dataResponse.data.map(value => {
          this.tableHeader[0].name = value["colName"];
          if (value["value"] !== null && value["value"] != '') {
            let tableRow: ITableRow = {
              editable: true, tdColumn: [{ editable: false, errorMessage: '', headerName: value["colName"], value: value["value"] }, { editable: false, errorMessage: '', headerName: value["col2Name"], value: value["col2Value"] }, { editable: false, errorMessage: '', headerName: 'CHNG_DATE', value: this._vtableService.formatDate(value["chnG_DATE"]) }, { editable: false, errorMessage: '', headerName: 'MOD_UID', value: value["moD_UID"] }], colspan: 1
            };
            this.tableData.push(tableRow);
          }
        })
      } else {
        dataResponse.data.map(value => {
          this.tableHeader[0].name = value["colName"];
          if (value["value"] !== null && value["value"] != '') {
            let tableRow: ITableRow = {
              editable: true, tdColumn: [{ editable: false, errorMessage: '', headerName: value["colName"], value: value["value"] }, { editable: false, errorMessage: '', headerName: 'CHNG_DATE', value: this._vtableService.formatDate(value["chnG_DATE"]) }, { editable: false, errorMessage: '', headerName: 'MOD_UID', value: value["moD_UID"] }], colspan: 1
            };
            this.tableData.push(tableRow);
          }
        })
      }
    }
    this.isDataModified = false;
  }

  saveVTableData() {
    let obj = new vTable();
    obj.TableName = this.selecteditem;
    this.tableData.map(x => {
      if(x.tdColumn.find(x => x.headerName === MOD_UID).value === '') {
        let data: ClassData = {
          Col2Value: x.tdColumn[1].value,
          ColValue: x.tdColumn[0].value
        }
        obj.classData.push(data);
      }
    });

    this._vtableService.saveData(obj).subscribe(x => {
      if (x.message) {
        this._alertErrorService.errorMessage.next(x.message);
      }
      this.populatedata(x);
    });
  }

  discarded(): void {
    let vTableDataGet = JSON.parse(sessionStorage.getItem(this.vtabledatakey));
    this.populatedata(vTableDataGet);
    this.isDataModified = false;
  }
}
