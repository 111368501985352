import { Injectable } from '@angular/core';
import { EndPoints } from './../app-config/endpoints';
import { HttpClient } from '@angular/common/http';
import { IFilterResponse } from '../../common/models/responses/interfaces/iFilterResponse.interface';
import { vTable } from '../../common/models/vTable.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VTableService {

  constructor(private http: HttpClient) { }

  getTableList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/VTable/GetTableList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }

  getTableData(name: string): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/VTable/GetTableData`;
    return this.http
      .get<IFilterResponse>(endpoint+"?value="+name);
  }

  deleteItem(tableName: string, itemName: string): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/VTable/DeleteItem`;
    return this.http
      .get<IFilterResponse>(endpoint + "?tableName=" + tableName + "&itemname=" + itemName);
  }

  saveData(data: vTable): Observable<IFilterResponse> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    let endpoint = `${EndPoints.API_BASE_URL}/VTable/SaveData`;
    return this.http
      .post<IFilterResponse>(endpoint, body, { 'headers': headers });
  }

  formatDate(dateString: string): string {
    const d = new Date(dateString);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
}
