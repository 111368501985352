import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import * as Excel from "exceljs";
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { timer } from 'rxjs';
import { IConnectionRequest } from 'src/app/common/models/requests/interfaces/ConnectionRequest';
import { IDeleteConnection } from 'src/app/common/models/responses/interfaces/IDeleteConnection.interface';
import { UnitOfMeasureModel } from 'src/app/common/models/unitOfMeasureModel';
import { AlertErrorService } from 'src/app/service/tcdb/alert-error.service';
import { UnitOfMeasureService } from 'src/app/service/tcdb/unitofmeasure.service';
import { Constants } from '../../../../common/constants/constant';
import { SealabilityTypes } from '../../../../common/enums/sealabilitytypes';
import { IExpansionPanelItem, IExpansionPanelModel } from '../../../../common/models/components/expansionpanelmodel.interface';
import { ITabComponentModel } from '../../../../common/models/components/iTabComponentModel';
import { CandidateConnectionResult } from '../../../../common/models/responses/candidateConnectionResult';
import { ICandidateConnectionResult, IEnvelope } from '../../../../common/models/responses/interfaces/icandidateConnectionResult.interface';
import { section } from '../../../../common/models/section.model';
import { deepCopy, sleep } from '../../../../common/utilities/utilities';
import { LoadCaseService } from '../../../../service/tcdb/loadcaseservice';
import { SearchCriteriaService } from '../../../../service/tcdb/searchcriteria.service';
import { TabStorageService } from '../../../../service/tcdb/tabstorage.service';
import { TestsInformationTabComponent } from '../../components/tests-information-tab/tests-information-tab.component';
import { ConnectionPerformanceSectionComponent } from '../../components/upload-utility/connection-performance-section/connection-performance-section.component';
import { DetailsSectionComponent } from '../../components/upload-utility/details-section/details-section.component';
import { TestInformationSectionComponent } from '../../components/upload-utility/test-information-section/test-information-section.component';
import { AXIAL_LOAD, DeleteConnection, ENVLP_TYPE_CD, ENVLP_TYPE_VTBL, MATERIAL_GRADE, NET_PRSR, PLOT_SEQ_NBR, SPECIMEN_TYPE, TEST_PROCEDURE, TEST_TYPE } from '../../components/upload-utility/upload-utility-helper';
import { UploadUtilityService } from '../upload-utility/upload-utility.service';
declare var require: any;
const htmlToPdfmake = require("html-to-pdfmake");
if (pdfFonts != undefined && pdfFonts.pdfMake != undefined && pdfFonts.pdfMake.vfs != undefined) {
  (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
}


@Component({
  selector: 'app-candidate-view-details',
  templateUrl: './candidate-view-details.component.html',
  styleUrls: ['./candidate-view-details.component.css']
})
export class CandidateViewDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild(DetailsSectionComponent) detailsSectionComponent: DetailsSectionComponent;
  @ViewChild(TestInformationSectionComponent) testInformationSectionComponent: TestInformationSectionComponent;
  @ViewChild(ConnectionPerformanceSectionComponent) connectionPerformanceSectionComponent: ConnectionPerformanceSectionComponent;
  @Input() candidateConnection: ICandidateConnectionResult = new CandidateConnectionResult();
  public liquidChart: Chart;
  public liquidChartPDF: Chart;
  public liquidCheckboxes = [];
  public gasChart: Chart;
  public gasChartPDF: Chart;
  public gasCheckboxes = [];
  public structuralChart: Chart;
  public structuralChartPDF: Chart;
  public structuralCheckboxes = [];
  public liquidChartToPDF;
  public gasChartToPDF;
  public structuralChartToPDF;
  @Output() backEvent = new EventEmitter<IDeleteConnection>();
  isDataSaved = false;
  tabActive = '';
  isUploadeUtility: boolean = false;
  hideDetailSection: boolean = false;
  section_liquid: string; section_structure: string; section_gas: string;
  tabList: ITabComponentModel[] = [];
  id: string
  sectionArrayList = [];
  caseDescription: string = '';
  public liquidSectionExpansionPanelArray: IExpansionPanelModel[] = [];
  public gasSectionExpansionPanelArray: IExpansionPanelModel[] = [];
  public structuralSectionExpansionPanelArray: IExpansionPanelModel[] = [];
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  public workbook = new Excel.Workbook();
  expansionPanelItem: IExpansionPanelItem[] = [];
  @ViewChild(TestsInformationTabComponent) public testsInformationTabComponent: TestsInformationTabComponent;
  successMessage: string = '';
  testInfoFields: string[] = [TEST_PROCEDURE, SPECIMEN_TYPE, MATERIAL_GRADE, TEST_TYPE];
  connPerfFields: string[] = [ENVLP_TYPE_CD, PLOT_SEQ_NBR];

  constructor(private _loadCaseService: LoadCaseService, private _searchCriteriaService: SearchCriteriaService, private route: ActivatedRoute,
    public uploadeUtilityService: UploadUtilityService,
    public errorAlertService: AlertErrorService,
    private router: Router,
    private unitMeasure: UnitOfMeasureService,
    private _msalService: MsalService,
    public tabStorageService: TabStorageService
  ) {
  }
  ngAfterViewInit(): void {
    this.tabList = [{ id: 'detailstab', title: 'Details' }, { id: 'testinfotab', title: 'Test Information' },
    { id: 'connectionPerformance', title: 'Connection Performance' },
    { id: 'liquidsealabilitytab', title: 'Liquid Sealability', isHeaderDisabled: !this.isDataSaved && this.isUploadeUtility }
      , { id: 'gasandliquidtab', title: 'Gas and Liquid Sealability', isHeaderDisabled: !this.isDataSaved && this.isUploadeUtility },
    { id: 'structuraltab', title: 'Structural', isHeaderDisabled: !this.isDataSaved && this.isUploadeUtility }
    ];
    // if (this.isUploadeUtility) {
    //   this.tabList.splice(2, 0, { id: 'connectionPerformance', title: 'Connection Performance' });
    // }
    this.tabList = !this.tabStorageService.adminUser ? this.tabList.filter((_, index) => index !== 2) : this.tabList;
  }

  ngOnInit() {
    Chart.register(annotationPlugin);
    this.tabActive = 'detailstab';
    this.drawCharts();
    this.caseDescription = this._loadCaseService.getwellsLocalStorage()?.caseDescription;
    this.route.paramMap.subscribe(params => {
      this.isUploadeUtility = Boolean(params.get('isUploadUtility'));
      this.hideDetailSection = !this.isUploadeUtility;
    });

    this.uploadeUtilityService.testInformationSectionRowUpdated.subscribe(x => {
      if (x) {
        this.isConnectionModified = x;
        this.uploadeUtilityService.isManualEntry = false;
      }
    });

    this.uploadeUtilityService.connectionPerformanceSectionRowUpdated.subscribe(x => {
      if (x) {
        this.isConnectionModified = x;
        this.uploadeUtilityService.isManualEntry = false;
      }
    });
    this.uploadeUtilityService.detailsFormDataDataChanged.subscribe(x => {
      if (x) {
        this.isConnectionModified = x;
        this.uploadeUtilityService.isManualEntry = false;
      }
    });

    this.unitMeasure.savedUnitOfMeasure.subscribe((changedUOM: UnitOfMeasureModel) => {
      if (changedUOM && changedUOM.unitOfMeasure !== undefined && this.candidateConnection.connectionId) {
        this._searchCriteriaService.searchWithAdditionalInfo([this.candidateConnection.connectionId]).subscribe((responseData) => {
          this.candidateConnection = responseData[0];
          this.candidateConnection.manufacturerLogo = this.uploadeUtilityService.getManufacturerLogo(this.candidateConnection.manufacturer);
          this.createLiquidChart();
          this.createGasChart();
          this.createStructuralChart();
        });
      }
    });
   
  }
  onTabClick(id: string) {
    this.tabActive = id;
  }

  handleNewEnvelopeTypeAdded(envelopeTypes: string[]) {
    if (this.detailsSectionComponent &&  envelopeTypes && envelopeTypes.length > 0) {
      this.detailsSectionComponent.vTableDependents[ENVLP_TYPE_VTBL] = envelopeTypes;
    }
  }

  onBackClick(isDeleteAction: boolean = false) {
    this.unitMeasure.MetricDisabled.next(false);
    this.backEvent.emit({ isDeletion: isDeleteAction });
    if (this.isUploadeUtility || this.isDataSaved) {
      this.router.navigate(['/addNewConnection']);
    }
  }

  createLiquidChart() {
    let checkedCounter = 0;
    let datasets = this.getCalculatedDatasets();
    let loadDatasets = this.getLoadsDatasets(SealabilityTypes.Liquid);
    loadDatasets.forEach(load => { datasets.push(load) });
    let annotations = this.getLoadAnnotations(SealabilityTypes.Liquid);
    annotations.push(this.getAnnotation(SealabilityTypes.Liquid));
    this.getWallThicknessOrHighCollapse(annotations, SealabilityTypes.Liquid);
    if (this.candidateConnection.dbEnvelopes && (this.candidateConnection.dbEnvelopes['Liquid'] !== undefined || this.candidateConnection.dbEnvelopes['liquid'] !== undefined)) {
      let liquidEnvelope: IEnvelope = this.candidateConnection.dbEnvelopes['Liquid'] == undefined ? this.candidateConnection.dbEnvelopes['liquid'] : this.candidateConnection.dbEnvelopes['Liquid'];

      if (liquidEnvelope !== null && liquidEnvelope.tension.length > 0 && liquidEnvelope.pressure.length > 0) {
        let liquidDataset =
        {
          label: Constants.LiquidSealabilityTitle,
          borderColor: 'red',
          backgroundColor: "red",
          fill: false,
          data: [],
          pointRadius: 1

        }
        for (var i = 0; i < liquidEnvelope.pressure.length; i++) {
          liquidDataset.data.push(
            {
              x: liquidEnvelope.tension[i],
              y: liquidEnvelope.pressure[i]
            });
        }
        datasets.unshift(liquidDataset);
      }
      if (Chart.getChart("LiquidChart")) {
        Chart.getChart("LiquidChart").destroy();
      }
      this.liquidChart = new Chart("LiquidChart", {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  return context[0].dataset.label;
                },
                label: function (context) {
                  return 'X:' + context.dataset.data[context.dataIndex]["x"];
                },
                afterLabel: function (context) {
                  return 'Y:' + context.dataset.data[context.dataIndex]["y"];
                }
              },
              displayColors: false
            },
            annotation: {
              annotations: annotations
            }
          },
          scales: {
            x: {
              title:
              {
                text: 'Axial Load (' + this.candidateConnection.tensionUOM + ')',
                display: true
              },
              type: 'linear',
              min: liquidEnvelope.minX,
              max: liquidEnvelope.maxX,
              ticks: {
                stepSize: liquidEnvelope.maxX * 1 / 4
              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1,
              }

            },
            y: {
              title:
              {
                text: 'Net Pressure Load (' + this.candidateConnection.pressureUOM + ')',
                display: true
              },
              type: 'linear',
              min: liquidEnvelope.minY,
              max: liquidEnvelope.maxY,
              ticks: {
                stepSize: liquidEnvelope.maxY * 1 / 4

              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1
              }

            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        }
      });
      if (Chart.getChart("LiquidChartPDF")) {
        Chart.getChart("LiquidChartPDF").destroy();
      }
      this.liquidChartPDF = new Chart("LiquidChartPDF", {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 37,
                boxHeight: 0,
                filter: item => item.hidden == false
              }
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  return context[0].dataset.label;
                },
                label: function (context) {
                  return 'X:' + context.dataset.data[context.dataIndex]["x"];
                },
                afterLabel: function (context) {
                  return 'Y:' + context.dataset.data[context.dataIndex]["y"];
                }
              },
              displayColors: false
            },
            annotation: {
              annotations: annotations
            }
          },
          scales: {
            x: {
              title:
              {
                text: 'Axial Load (' + this.candidateConnection.tensionUOM + ')',
                display: true
              },
              type: 'linear',
              min: liquidEnvelope.minX,
              max: liquidEnvelope.maxX,
              ticks: {
                stepSize: liquidEnvelope.maxX * 1 / 4
              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1,
              }

            },
            y: {
              title:
              {
                text: 'Net Pressure Load (' + this.candidateConnection.pressureUOM + ')',
                display: true
              },
              type: 'linear',
              min: liquidEnvelope.minY,
              max: liquidEnvelope.maxY,
              ticks: {
                stepSize: liquidEnvelope.maxY * 1 / 4

              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1
              }

            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        },
        plugins: [
          {
            id: 'liquidchartpdflegend',
            afterUpdate: (chart, args, opts) => {
              chart.legend?.legendItems?.forEach((label) => {
                if ((label.fillStyle == "purple")) {
                  if (this.expansionPanelItem.length == 0) {
                    checkedCounter = 0;
                    this.sectionArrayList[0].loadArray.forEach((load) => {
                      if (load.name == label.text) {
                        label.text = label.text + "(" + (checkedCounter + 1) + ")";
                      }
                      else { }
                      checkedCounter++;
                    })
                  }
                  else if (this.expansionPanelItem.find(x => x.name == label.text) != undefined) {
                    let indexUpdated = this.expansionPanelItem.findIndex(item => item.name === this.expansionPanelItem.find(x => x.name == label.text).name);
                    label.text = label.text + "(" + (indexUpdated + 1) + ")";
                  }
                  return label;
                }
                else {
                  return label;
                }
                ;
              });
            },
          },
        ],
      })
    }

  }


  getCalculatedDatasets() {
    let datasests = [];
    if (this.candidateConnection.calculatedEnvelopes) {
      Object.entries(this.candidateConnection.calculatedEnvelopes).forEach(([key, value]) => {
        if (value !== null && value.tension.length > 0 && value.pressure.length > 0) {
          let calculatedDataset =
          {
            label: this.getCalculatedLegend(key),
            backgroundColor: this.getCalculatedColor(key),
            borderColor: this.getCalculatedColor(key),
            borderDash: this.getCalculatedBorderDash(key),
            fill: false,
            data: []

          }
          for (var i = 0; i < value.pressure.length; i++) {
            calculatedDataset.data.push(
              {
                x: value.tension[i],
                y: value.pressure[i]
              });
          }
          datasests.push(calculatedDataset);
        }
      });
    }

    return datasests;
  }

  getLoadsDatasets(envelopeType: SealabilityTypes) {
    let sectionArray = this._loadCaseService.getLoadsLocalStorage();
    let datasests = [];
    if (sectionArray) {
      let i = 0;
      sectionArray.forEach(section => {
        if (section && section.loadArray) {
          let isFirstSection = i == 0;
          let loadCount = 0;
          section.loadArray.forEach(load => {
            var isToShowLoad = load.sealabilityEnvelopes.find(p => p.checked && p.name.toLowerCase() === envelopeType.toLowerCase());
            if (load.pressureAndTensionList.length > 0 && isToShowLoad) {
              let loadDataset =
              {
                label: load.name,
                backgroundColor: 'purple',
                borderColor: 'purple',
                hidden: !isFirstSection,
                borderWidth: 2,
                fill: false,
                data: [],
                pointRadius: 0,
                index: (loadCount + 1),
                section: section.name
              }
              loadCount++;

              load.pressureAndTensionList.forEach(loadAndPressure => {
                loadDataset.data.push(
                  {
                    x: +loadAndPressure.tension,
                    y: +loadAndPressure.pressure
                  });
              });
              datasests.push(loadDataset);
            }
          });
          i++;
        }
      });
    }

    return datasests;
  }

  getCalculatedColor(value) {
    switch (value) {
      case 'pipeBodyUni':
        return 'green';
      case 'connUni':
        return 'orange';
      case 'pipeBodyVM':
        return 'black';
      case 'pipeBodyRup':
        return 'blue';
    }
  }

  getCalculatedLegend(value) {
    switch (value) {
      case 'pipeBodyUni':
        return 'Pipe Body Uniaxial Ratings';
      case 'connUni':
        return 'Connection Uniaxial Ratings (from vendor)';
      case 'pipeBodyVM':
        return 'Pipe Body VME (internal yield)';
      case 'pipeBodyRup':
        return 'Pipe Body Rupture Envelope';
    }
  }

  getCalculatedBorderDash(value) {
    let response = [];
    switch (value) {
      case 'pipeBodyUni':
        response.push(5);
        return response;
      case 'connUni':
        response.push(20);
        return response;
      case 'pipeBodyVM':
        response.push(0);
        return response;
      case 'pipeBodyRup':
        response.push(0);
        return response;
    }
  }
  onLiquidCheckboxChange(event: any, legendTitle: string) {
    let legendHidden = this.liquidChart.data.datasets.find(p => p.label === legendTitle);
    let checkbox = this.liquidCheckboxes.find(p => p.title == legendTitle);
    checkbox.checked = event.target.checked;
    legendHidden.hidden = !event.target.checked;
    this.liquidChart.update();
  }

  createGasChart() {
    let datasets = this.getCalculatedDatasets();
    let loadDatasets = this.getLoadsDatasets(SealabilityTypes.Gas);
    loadDatasets.forEach(load => { datasets.push(load) });
    let annotations = this.getLoadAnnotations(SealabilityTypes.Gas);
    annotations.push(this.getAnnotation(SealabilityTypes.Gas));
    this.getWallThicknessOrHighCollapse(annotations, SealabilityTypes.Gas);

    if (this.candidateConnection.calculatedEnvelopes && (this.candidateConnection.dbEnvelopes['Gas'] !== undefined || this.candidateConnection.dbEnvelopes['gas'] !== undefined)) {
      let gasEnvelope: IEnvelope = this.candidateConnection.dbEnvelopes['Gas'] == undefined ? this.candidateConnection.dbEnvelopes['gas'] : this.candidateConnection.dbEnvelopes['Gas'];

      if (gasEnvelope !== null && gasEnvelope.tension.length > 0 && gasEnvelope.pressure.length > 0) {
        let gasDataset =
        {
          label: Constants.GasSealabilityTitle,
          borderColor: 'red',
          backgroundColor: "red",
          fill: false,
          data: [],
          pointRadius: 1

        }
        for (var i = 0; i < gasEnvelope.pressure.length; i++) {
          gasDataset.data.push(
            {
              x: gasEnvelope.tension[i],
              y: gasEnvelope.pressure[i]
            });
        }
        datasets.unshift(gasDataset);
      }
      if (Chart.getChart("GasChart")) {
        Chart.getChart("GasChart").destroy();
      }
      this.gasChart = new Chart("GasChart", {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  return context[0].dataset.label;
                },
                label: function (context) {
                  return 'X:' + context.dataset.data[context.dataIndex]["x"];
                },
                afterLabel: function (context) {
                  return 'Y:' + context.dataset.data[context.dataIndex]["y"];
                }
              },
              displayColors: false
            },
            annotation: {
              annotations: annotations
            }
          },
          scales: {
            x: {
              title:
              {
                text: 'Axial Load (' + this.candidateConnection.tensionUOM + ')',
                display: true
              },
              type: 'linear',
              min: gasEnvelope.minX,
              max: gasEnvelope.maxX,
              ticks: {
                stepSize: gasEnvelope.maxX * 1 / 4
              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1,
              }

            },
            y: {
              title:
              {
                text: 'Net Pressure Load (' + this.candidateConnection.pressureUOM + ')',
                display: true
              },
              type: 'linear',
              min: gasEnvelope.minY,
              max: gasEnvelope.maxY,
              ticks: {
                stepSize: gasEnvelope.maxY * 1 / 4

              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1
              }

            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        }
      });
      if (Chart.getChart("GasChartPDF")) {
        Chart.getChart("GasChartPDF").destroy();
      }
      this.gasChartPDF = new Chart("GasChartPDF", {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 37,
                boxHeight: 0,
                filter: item => item.hidden == false
              }
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  return context[0].dataset.label;
                },
                label: function (context) {
                  return 'X:' + context.dataset.data[context.dataIndex]["x"];
                },
                afterLabel: function (context) {
                  return 'Y:' + context.dataset.data[context.dataIndex]["y"];
                }
              },
              displayColors: false
            },
            annotation: {
              annotations: annotations
            }
          },
          scales: {
            x: {
              title:
              {
                text: 'Axial Load (' + this.candidateConnection.tensionUOM + ')',
                display: true
              },
              type: 'linear',
              min: gasEnvelope.minX,
              max: gasEnvelope.maxX,
              ticks: {
                stepSize: gasEnvelope.maxX * 1 / 4
              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1,
              }

            },
            y: {
              title:
              {
                text: 'Net Pressure Load (' + this.candidateConnection.pressureUOM + ')',
                display: true
              },
              type: 'linear',
              min: gasEnvelope.minY,
              max: gasEnvelope.maxY,
              ticks: {
                stepSize: gasEnvelope.maxY * 1 / 4

              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1
              }

            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        },
        plugins: [
          {
            id: 'gaschartpdflegend',
            afterUpdate: (chart, args, opts) => {
              chart.legend?.legendItems?.forEach((label) => {
                if ((label.fillStyle == "purple")) {
                  if (this.expansionPanelItem.length == 0) {
                    let checkedCounter = 0;
                    this.sectionArrayList[0].loadArray.forEach((load) => {
                      if (load.name == label.text) {
                        label.text = label.text + "(" + (checkedCounter + 1) + ")";
                      }
                      else { }
                      checkedCounter++;
                    })
                  }
                  else if (this.expansionPanelItem.find(x => x.name == label.text) != undefined) {
                    let indexUpdated = this.expansionPanelItem.findIndex(item => item.name === this.expansionPanelItem.find(x => x.name == label.text).name);
                    label.text = label.text + "(" + (indexUpdated + 1) + ")";
                  }
                  return label;
                }
                else {
                  return label;
                }

              });
            },
          },
        ],
      });
    }

  }


  onGasCheckboxChange(event: any, legendTitle: string) {
    let legendHidden = this.gasChart.data.datasets.find(p => p.label === legendTitle);
    let checkbox = this.gasCheckboxes.find(p => p.title == legendTitle);
    checkbox.checked = event.target.checked;
    legendHidden.hidden = !event.target.checked;
    this.gasChart.update();
  }

  createStructuralChart() {
    let checkedCounter = 0;
    let datasets = this.getCalculatedDatasets();
    let loadDatasets = this.getLoadsDatasets(SealabilityTypes.Structural);
    loadDatasets.forEach(load => { datasets.push(load) });
    let annotations = this.getLoadAnnotations(SealabilityTypes.Structural);
    annotations.push(this.getAnnotation(SealabilityTypes.Structural));
    this.getWallThicknessOrHighCollapse(annotations, SealabilityTypes.Structural);

    if (this.candidateConnection.dbEnvelopes && (this.candidateConnection.dbEnvelopes['Structural'] !== undefined || this.candidateConnection.dbEnvelopes['structural'] !== undefined)) {
      let structuralEnvelope: IEnvelope = this.candidateConnection.dbEnvelopes['Structural'] == undefined ? this.candidateConnection.dbEnvelopes['structural'] : this.candidateConnection.dbEnvelopes['Structural'];

      if (structuralEnvelope !== null && structuralEnvelope.tension.length > 0 && structuralEnvelope.pressure.length > 0) {
        let liquidDataset =
        {
          label: Constants.StructuralSealabilityTitle,
          borderColor: 'red',
          backgroundColor: "red",
          fill: false,
          data: [],
          pointRadius: 1

        }
        for (var i = 0; i < structuralEnvelope.pressure.length; i++) {
          liquidDataset.data.push(
            {
              x: structuralEnvelope.tension[i],
              y: structuralEnvelope.pressure[i]
            });
        }
        datasets.unshift(liquidDataset);
      }
      if (Chart.getChart("StructuralChart")) {
        Chart.getChart("StructuralChart").destroy();
      }
      this.structuralChart = new Chart("StructuralChart", {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  return context[0].dataset.label;
                },
                label: function (context) {
                  return 'X:' + context.dataset.data[context.dataIndex]["x"];
                },
                afterLabel: function (context) {
                  return 'Y:' + context.dataset.data[context.dataIndex]["y"];
                }
              },
              displayColors: false
            }, annotation: {
              annotations: annotations
            }
          },
          scales: {
            x: {
              title:
              {
                text: 'Axial Load (' + this.candidateConnection.tensionUOM + ')',
                display: true
              },
              type: 'linear',
              min: structuralEnvelope.minX,
              max: structuralEnvelope.maxX,
              ticks: {
                stepSize: structuralEnvelope.maxX * 1 / 4
              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1,
              }

            },
            y: {
              title:
              {
                text: 'Net Pressure Load (' + this.candidateConnection.pressureUOM + ')',
                display: true
              },
              type: 'linear',
              min: structuralEnvelope.minY,
              max: structuralEnvelope.maxY,
              ticks: {
                stepSize: structuralEnvelope.maxY * 1 / 4

              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1
              }

            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        }
      });
      if (Chart.getChart("StructuralChartPDF")) {
        Chart.getChart("StructuralChartPDF").destroy();
      }
      this.structuralChartPDF = new Chart("StructuralChartPDF", {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 37,
                boxHeight: 0,
                filter: item => item.hidden == false
              }
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  return context[0].dataset.label;
                },
                label: function (context) {
                  return 'X:' + context.dataset.data[context.dataIndex]["x"];
                },
                afterLabel: function (context) {
                  return 'Y:' + context.dataset.data[context.dataIndex]["y"];
                }
              },
              displayColors: false
            }, annotation: {
              annotations: annotations
            }
          },
          scales: {
            x: {
              title:
              {
                text: 'Axial Load (' + this.candidateConnection.tensionUOM + ')',
                display: true
              },
              type: 'linear',
              min: structuralEnvelope.minX,
              max: structuralEnvelope.maxX,
              ticks: {
                stepSize: structuralEnvelope.maxX * 1 / 4
              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1,
              }

            },
            y: {
              title:
              {
                text: 'Net Pressure Load (' + this.candidateConnection.pressureUOM + ')',
                display: true
              },
              type: 'linear',
              min: structuralEnvelope.minY,
              max: structuralEnvelope.maxY,
              ticks: {
                stepSize: structuralEnvelope.maxY * 1 / 4

              },
              grid: {
                lineWidth: context => context.tick?.value == 0 ? 3 : 1
              }

            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        },
        plugins: [
          {
            id: 'structuralchartpdflegends',
            afterUpdate: (chart, args, opts) => {
              chart.legend?.legendItems?.forEach((label) => {
                if ((label.fillStyle == "purple")) {
                  if (this.expansionPanelItem.length == 0) {
                    checkedCounter = 0;
                    this.sectionArrayList[0].loadArray.forEach((load) => {
                      if (load.name == label.text) {
                        label.text = label.text + "(" + (checkedCounter + 1) + ")";
                      }
                      else { }
                      checkedCounter++;
                    })
                  }
                  else if (this.expansionPanelItem.find(x => x.name == label.text) != undefined) {
                    let indexUpdated = this.expansionPanelItem.findIndex(item => item.name === this.expansionPanelItem.find(x => x.name == label.text).name);
                    label.text = label.text + "(" + (indexUpdated + 1) + ")";
                  }
                  return label;
                }
                else {
                  return label;
                }
                ;
              });
            },
          },
        ],
      });
    }
  }


  onStructuralCheckboxChange(event: any, legendTitle: string) {
    let legendHidden = this.structuralChart.data.datasets.find(p => p.label === legendTitle);
    let checkbox = this.structuralCheckboxes.find(p => p.title == legendTitle);
    checkbox.checked = event.target.checked;
    legendHidden.hidden = !event.target.checked;
    this.structuralChart.update();
  }

  getSealabilityCheckboxes(sealabilityTypeKey: string, sealabilityTypeTitle: string) {
    let checkBoxes = [];
    if (this.candidateConnection.calculatedEnvelopes) {
      Object.entries(this.candidateConnection.calculatedEnvelopes).forEach(([key, value]) => {
        checkBoxes.push({ key: key, title: this.getCalculatedLegend(key), checked: true });
      });

      checkBoxes.push({ key: sealabilityTypeKey, title: sealabilityTypeTitle, checked: true });
    }
    return checkBoxes;
  }
  async openPDF() {
    if (Chart.getChart("LiquidChartPDF") !== undefined) {
      this.liquidChartToPDF = Chart.getChart("LiquidChartPDF").toBase64Image();
    }
    if (Chart.getChart("GasChartPDF") !== undefined) {
      this.gasChartToPDF = Chart.getChart("GasChartPDF").toBase64Image();
    }
    if (Chart.getChart("StructuralChartPDF") !== undefined) {
      this.structuralChartToPDF = Chart.getChart("StructuralChartPDF").toBase64Image();
    }
    // Wait for angular render before taking the element
    await sleep(100);
    const pdfTableDetails = document.getElementById('htmlDataDetails');
    var htmlDetails = htmlToPdfmake(pdfTableDetails.innerHTML);
    const pdfTableLiquid = document.getElementById('htmlDataLiquid');
    var htmlLiquid = htmlToPdfmake(pdfTableLiquid.innerHTML);
    const pdfTableGas = document.getElementById('htmlDataGas');
    var htmlGas = htmlToPdfmake(pdfTableGas.innerHTML);
    const pdfTableStructural = document.getElementById('htmlDataStructural');
    var htmlStructural = htmlToPdfmake(pdfTableStructural.innerHTML);
    const pdfTableCaseDescription = document.getElementById('htmlCaseDescription');
    var htmlDataCaseDescription = htmlToPdfmake(pdfTableCaseDescription.innerHTML);
    const pdfTableSectionArray = document.getElementById('htmlCaseSectionArray');
    var htmlSectionArray = htmlToPdfmake(pdfTableSectionArray.innerHTML);
    const documentDefinition = {
      content: [htmlDetails, htmlLiquid, htmlGas, htmlStructural, htmlDataCaseDescription, htmlSectionArray],
      pageBreakBefore: function (currentNode) {
        return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
      },
      pageOrientation: 'portrait',
      info: {
        title: 'CandidateConnectionReport'
      },
      defaultStyle: {
        fontSize: 12
      },
      footer: (currentPage, pageCount) => {
        var t = {
          layout: "noBorders",
          fontSize: 8,
          margin: [25, 0, 25, 0],
          table: {
            widths: ["*", "*"],
            body: [
              [
                { text: "Connection Evaluation Summary - Not for Public Distribution", alignment: 'left', },
                { text: "Page  " + currentPage.toString() + " of " + pageCount, alignment: 'right', }
              ]
            ]
          }
        };

        return t;
      }
    };
    var tableLayouts = {
      exampleLayout: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return (i === node.table.headerRows) ? 2 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i) {
          return i === 1 ? 'black' : '#aaa';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        }
      }
    };
    pdfMake.createPdf(documentDefinition, tableLayouts).download("CandidateConnectionReport");
  }

  updateExpansionPanel(sectionExpansionPanel: IExpansionPanelModel, chart: string) {
    this.expansionPanelItem = sectionExpansionPanel.items;
    let allOtherSection = [];
    if (this.liquidChart != undefined) {
      this.liquidChart.options.plugins.annotation.annotations = [];
    }
    if (this.liquidChartPDF != undefined) {
      this.liquidChartPDF.options.plugins.annotation.annotations = [];
    }
    if (this.gasChart != undefined) {
      this.gasChart.options.plugins.annotation.annotations = [];
    }
    if (this.gasChartPDF) {
      this.gasChartPDF.options.plugins.annotation.annotations = [];
    }
    if (this.structuralChart != undefined) {
      this.structuralChart.options.plugins.annotation.annotations = [];
    }
    if (this.structuralChartPDF != undefined) {
      this.structuralChartPDF.options.plugins.annotation.annotations = [];
    }
    switch (chart) {
      case 'liquid':
        if (this.liquidChart) {
          let loadAnnotation = this.getLoadAnnotations(SealabilityTypes.Liquid, sectionExpansionPanel);
          loadAnnotation.push(this.getAnnotation(SealabilityTypes.Liquid));
          this.liquidChart.options.plugins.annotation.annotations = loadAnnotation;
          this.liquidChart.update();
        }
        if (this.liquidChartPDF) {
          let loadAnnotation = this.getLoadAnnotations(SealabilityTypes.Liquid, sectionExpansionPanel);
          loadAnnotation.push(this.getAnnotation(SealabilityTypes.Liquid));
          this.liquidChartPDF.options.plugins.annotation.annotations = loadAnnotation;
          this.liquidChartPDF.update();
        }

        if (sectionExpansionPanel.items.filter(p => p.checked).length > 0) {
          sectionExpansionPanel.expansionCheckboxChecked = true;
          allOtherSection = this.liquidSectionExpansionPanelArray.filter(p => p.title !== sectionExpansionPanel.title);
        }
        break;
      case 'gas':
        if (this.gasChart) {
          let loadAnnotation = this.getLoadAnnotations(SealabilityTypes.Gas, sectionExpansionPanel);
          loadAnnotation.push(this.getAnnotation(SealabilityTypes.Gas));
          this.gasChart.options.plugins.annotation.annotations = loadAnnotation;
          this.gasChart.update();
        }
        if (this.gasChartPDF) {
          let loadAnnotation = this.getLoadAnnotations(SealabilityTypes.Gas, sectionExpansionPanel);
          loadAnnotation.push(this.getAnnotation(SealabilityTypes.Gas));
          this.gasChartPDF.options.plugins.annotation.annotations = loadAnnotation;
          this.gasChartPDF.update();
        }

        if (sectionExpansionPanel.items.filter(p => p.checked).length > 0) {
          sectionExpansionPanel.expansionCheckboxChecked = true;
          allOtherSection = this.gasSectionExpansionPanelArray.filter(p => p.title !== sectionExpansionPanel.title);
        }
        break;
      case 'structural':
        if (this.structuralChart) {
          let loadAnnotation = this.getLoadAnnotations(SealabilityTypes.Structural, sectionExpansionPanel);
          loadAnnotation.push(this.getAnnotation(SealabilityTypes.Structural));
          this.structuralChart.options.plugins.annotation.annotations = loadAnnotation;
          this.structuralChart.update();
        }
        if (this.structuralChartPDF) {
          let loadAnnotation = this.getLoadAnnotations(SealabilityTypes.Structural, sectionExpansionPanel);
          loadAnnotation.push(this.getAnnotation(SealabilityTypes.Structural));
          this.structuralChartPDF.options.plugins.annotation.annotations = loadAnnotation;
          this.structuralChartPDF.update();
        }

        if (sectionExpansionPanel.items.filter(p => p.checked).length > 0) {
          sectionExpansionPanel.expansionCheckboxChecked = true;
          allOtherSection = this.structuralSectionExpansionPanelArray.filter(p => p.title !== sectionExpansionPanel.title);
        }
        break;
      default:
    }

    allOtherSection.forEach(sectionExpansionPanel => {
      sectionExpansionPanel.expansionCheckboxChecked = false;
      sectionExpansionPanel.items.forEach(loadCheckbox => {
        loadCheckbox.checked = false;
      });
    });

    this.updateLoadChartBasedOnCheckboxes(chart);
    this.updateLoadChartBasedOnCheckboxesPDF(chart);
  }

  updateLoadChartBasedOnCheckboxes(chart: string) {
    switch (chart) {
      case 'liquid':
        if (this.liquidSectionExpansionPanelArray && this.liquidChart) {
          const objLiquidDBClone: any = Object.create(this.liquidChart.data.datasets);
          this.liquidSectionExpansionPanelArray.forEach(expansionPanel => {
            let title = expansionPanel.title;
            expansionPanel.items.forEach(item => {
              let liquidHidden = objLiquidDBClone.find(p => p.label === item.name && p.section === title);
              if (liquidHidden) {
                liquidHidden.hidden = !item.checked;
                this.liquidChart.update();
              }
            });

          });
        }
        break;
      case 'gas':
        if (this.gasSectionExpansionPanelArray && this.gasChart) {
          const objGasDBClone: any = Object.create(this.gasChart.data.datasets);
          this.gasSectionExpansionPanelArray.forEach(expansionPanel => {
            let title = expansionPanel.title;
            expansionPanel.items.forEach(item => {
              let gasHidden = objGasDBClone.find(p => p.label === item.name && p.section === title);
              if (gasHidden) {
                gasHidden.hidden = !item.checked;
                this.gasChart.update();
              }
            });
          });
        }
        break;
      case 'structural':
        if (this.structuralSectionExpansionPanelArray && this.structuralChart) {
          const objStructDBClone: any = Object.create(this.structuralChart.data.datasets);
          this.structuralSectionExpansionPanelArray.forEach(expansionPanel => {
            let title = expansionPanel.title;
            expansionPanel.items.forEach(item => {
              let structuralHidden = objStructDBClone.find(p => p.label === item.name && p.section === title);
              if (structuralHidden) {
                structuralHidden.hidden = !item.checked;
                this.structuralChart.update();
              }
            });
          });
        }
        break;
    }
  }

  updateLoadChartBasedOnCheckboxesPDF(chart: string) {
    switch (chart) {
      case 'liquid':
        if (this.liquidSectionExpansionPanelArray && this.liquidChartPDF) {
          this.liquidSectionExpansionPanelArray.forEach(expansionPanel => {
            expansionPanel.items.forEach(item => {
              let liquidHidden = this.liquidChartPDF.data.datasets.find(p => p.label === item.name && item.checked == true);
              if (liquidHidden) {
                this.liquidChartPDF.update();
              }
            });

          });
        }
        break;
      case 'gas':
        if (this.gasSectionExpansionPanelArray && this.gasChartPDF) {
          this.gasSectionExpansionPanelArray.forEach(expansionPanel => {
            expansionPanel.items.forEach(item => {
              let gasHidden = this.gasChart.data.datasets.find(p => p.label === item.name && item.checked == true);
              if (gasHidden) {
                gasHidden.hidden = !item.checked;
                this.gasChartPDF.update();
              }
            });
          });
        }
        break;
      case 'structural':
        if (this.structuralSectionExpansionPanelArray && this.structuralChartPDF) {
          this.structuralSectionExpansionPanelArray.forEach(expansionPanel => {
            expansionPanel.items.forEach(item => {
              let structuralHidden = this.structuralChart.data.datasets.find(p => p.label === item.name && item.checked == true);
              if (structuralHidden) {
                structuralHidden.hidden = !item.checked;
                this.structuralChartPDF.update();
              }
            });
          });
        }
        break;
    }
  }

  createLoadsCheckboxes() {
    let sectionArray = this._loadCaseService.getLoadsLocalStorage();
    let i = 0;
    if (sectionArray) {
      sectionArray.forEach((section) => {
        let isTheFirstSection = i == 0;
        let isLiquidSectionDisabled = false, isGasSectionDisabled = false, isStructuralSectionDisabled = false;

        let liquidItems = this.getLoadsForEachOne(section, SealabilityTypes.Liquid, isTheFirstSection, isLiquidSectionDisabled);
        let gasItems = this.getLoadsForEachOne(section, SealabilityTypes.Gas, isTheFirstSection, isGasSectionDisabled);
        let structuralItems = this.getLoadsForEachOne(section, SealabilityTypes.Structural, isTheFirstSection, isStructuralSectionDisabled);

        this.liquidSectionExpansionPanelArray.push({ items: deepCopy(liquidItems), title: section.name, usecheckbox: true, sort: 1, enableSecondLevel: false, icon: '', expansionCheckbox: true, expansionCheckboxChecked: isTheFirstSection, isDisabled: isLiquidSectionDisabled });
        this.gasSectionExpansionPanelArray.push({ items: deepCopy(gasItems), title: section.name, usecheckbox: true, sort: 1, enableSecondLevel: false, icon: '', expansionCheckbox: true, expansionCheckboxChecked: isTheFirstSection, isDisabled: isGasSectionDisabled });
        this.structuralSectionExpansionPanelArray.push({ items: deepCopy(structuralItems), title: section.name, usecheckbox: true, sort: 1, enableSecondLevel: false, icon: '', expansionCheckbox: true, expansionCheckboxChecked: isTheFirstSection, isDisabled: isStructuralSectionDisabled });
        i++;
      });
    }
  }

  getLoadsForEachOne(section: section, envelopeType: SealabilityTypes, isTheFirstSection: boolean, isSectionDisabled: boolean) {
    let loadArrayItems = [];
    section.loadArray.forEach((load) => {
      let loadDisabled = !load.sealabilityEnvelopes.find(p => p.checked && p.name.toLowerCase() === envelopeType.toLowerCase()) || load.pressureAndTensionList.length == 0;
      if (!loadDisabled)
        isSectionDisabled = false;
      var loadExpansionPanel: IExpansionPanelItem = { value: load.name, name: load.name, checked: isTheFirstSection, items: [], color: 'purple', isDisabled: loadDisabled };
      loadArrayItems.push(loadExpansionPanel);
    });
    return loadArrayItems;
  }

  getLoadAnnotations(envelopeType: SealabilityTypes, sectionExpansionPanel?: IExpansionPanelModel) {
    let sectionArray = this._loadCaseService.getLoadsLocalStorage();
    this.sectionArrayList = sectionArray;
    let loadAnnotationArray = [];
    let i = 0;

    if (sectionArray) {
      if (sectionExpansionPanel == undefined) {
        this.section_liquid = sectionArray[0].name;
        this.section_structure = sectionArray[0].name;
        this.section_gas = sectionArray[0].name;
      }
      else if (envelopeType == "Liquid" && sectionExpansionPanel != undefined) {
        this.section_liquid = sectionExpansionPanel.title;
      }
      else if (envelopeType == "Gas" && sectionExpansionPanel != undefined) {
        this.section_gas = sectionExpansionPanel.title;
      }
      else if (envelopeType == "Structural" && sectionExpansionPanel != undefined) {
        this.section_structure = sectionExpansionPanel.title;
      }
      sectionArray.forEach((section) => {
        let isTheFirstSection = i == 0;
        let CheckCounter = 0;

        if ((isTheFirstSection && !sectionExpansionPanel) || section.name == sectionExpansionPanel?.title) {
          section.loadArray.forEach((load) => {
            if (load.sealabilityEnvelopes.find(p => p.checked && p.name.toLowerCase() == envelopeType.toLowerCase())) {
              if (load.pressureAndTensionList && load.pressureAndTensionList.length > 0) {
                let loadAnnotation = {
                  type: 'label',
                  xValue: load.pressureAndTensionList[load.pressureAndTensionList.length - 1].tension,
                  yValue: load.pressureAndTensionList[load.pressureAndTensionList.length - 1].pressure,
                  content: CheckCounter + 1,
                  font: {
                    size: 18,
                    opacity: 1,
                    weight: 'bold'
                  },
                  display: sectionExpansionPanel?.items?.filter(p => p.name == load.name)[0].checked
                };

                loadAnnotationArray.push(loadAnnotation);
              }
            }
            CheckCounter++;

          });
        }
        i++;
      });
    }
    return loadAnnotationArray;
  }

  getAnnotation(sealabilityType: string) {
    if (this.candidateConnection && this.candidateConnection.dbEnvelopes) {
      let sealabilityEnvelope: IEnvelope = this.candidateConnection.dbEnvelopes[sealabilityType] == undefined ? this.candidateConnection.dbEnvelopes[sealabilityType.replace(/\b\w/g, char => char.toLowerCase())] : this.candidateConnection.dbEnvelopes[sealabilityType];
      if (sealabilityEnvelope) {
        return this.getDrawingNumbers(sealabilityEnvelope.minX, sealabilityEnvelope.maxX, sealabilityEnvelope.maxY);
      }
    }
  }

  getDrawingNumbers(minX, maxX, maxY) {
    return {
      type: 'label',
      display: true,
      xValue: minX + (maxX / 5),
      yValue: maxY - (maxY / 5),
      backgroundColor: 'rgba(211,211,211,100)',
      content: ['Drawing Numbers:', 'Box:  ' + this.candidateConnection.box, 'Pin:   ' + this.candidateConnection.pin],
      font: {
        size: 11
      },
      color: 'black',
      textAlign: 'left',
      padding: 10
    }
  }
  drawCharts() {
    this.createLiquidChart();
    this.createGasChart();
    this.createStructuralChart();
    this.createLoadsCheckboxes();
    this.createWatermark();
    this.updateLoadChartBasedOnCheckboxes('liquid');
    this.updateLoadChartBasedOnCheckboxes('gas');
    this.updateLoadChartBasedOnCheckboxes('structural');
    this.setSealabilityCheckboxes();
  }
  setSealabilityCheckboxes() {
    this.liquidCheckboxes = this.getSealabilityCheckboxes(SealabilityTypes.Liquid, Constants.LiquidSealabilityTitle);
    this.gasCheckboxes = this.getSealabilityCheckboxes(SealabilityTypes.Gas, Constants.GasSealabilityTitle);
    this.structuralCheckboxes = this.getSealabilityCheckboxes(SealabilityTypes.Structural, Constants.StructuralSealabilityTitle);
  }

  exportDataToExcel(workbook: Workbook) {
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, 'TCDB Exported Envelopes.xlsx');
    });
  }

  downloadCandidateExcelFile() {
    let connectionId = this.candidateConnection.connectionId;
    let sectionArray = this._loadCaseService.getLoadsLocalStorage();
    this._searchCriteriaService.generateExcelByCandidateConnection(connectionId, sectionArray).subscribe(response => {
      const blob = new Blob([response.body], { type: response.headers.get('content-type') });
      let fileName = 'TCDB Exported Envelopes.xlsx';
      const file = new File([blob], fileName, { type: response.headers.get('content-type') });
      saveAs(file);
    });
  }

  createWorksheet(isTest = false) {
    this.workbook = new Excel.Workbook();

    // create a sheet with red tab colour
    const worksheet = this.workbook.addWorksheet('Envelopes', { properties: { tabColor: { argb: 'FFC0000' } } });

    const titleRow = [];
    titleRow[1] = `${this.candidateConnection.manufacturer} ${this.candidateConnection.connectionName} - OD: ${this.candidateConnection.od} ${this.candidateConnection.odUOM}, WEIGHT: ${this.candidateConnection.weight} ${this.candidateConnection.weightUOM}, YIELD: ${this.candidateConnection.yield} ${this.candidateConnection.yieldUOM}`;
    worksheet.addRow(titleRow);

    //Empty row between title and data
    worksheet.addRow({});

    //Add Title
    worksheet.mergeCells("A3:B3");
    worksheet.getCell("B3").value = 'Gas Sealability';
    worksheet.mergeCells("D3:E3");
    worksheet.getCell("E3").value = 'Liquid Sealability';
    worksheet.mergeCells("G3:H3");
    worksheet.getCell("H3").value = 'Structural';
    worksheet.mergeCells("J3:K3");
    worksheet.getCell("K3").value = 'Loads';

    //Add style to title
    worksheet.getCell('B3').alignment = { horizontal: 'center' };
    worksheet.getCell('B3').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '808080' } };
    worksheet.getCell('B3').font = { bold: true };
    worksheet.getCell('E3').alignment = { horizontal: 'center' };
    worksheet.getCell('E3').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '808080' } };
    worksheet.getCell('E3').font = { bold: true };
    worksheet.getCell('H3').alignment = { horizontal: 'center' };
    worksheet.getCell('H3').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '808080' } };
    worksheet.getCell('H3').font = { bold: true };
    worksheet.getCell('K3').alignment = { horizontal: 'center' };
    worksheet.getCell('K3').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '808080' } };
    worksheet.getCell('K3').font = { bold: true };

    // Add Subtitle
    const subtitleValues = [];
    subtitleValues[1] = `Axial Load (${this.candidateConnection.tensionUOM})`;
    subtitleValues[2] = `Pressure Load (${this.candidateConnection.pressureUOM})`;
    subtitleValues[4] = `Axial Load (${this.candidateConnection.tensionUOM})`;
    subtitleValues[5] = `Pressure Load (${this.candidateConnection.pressureUOM})`;
    subtitleValues[7] = `Axial Load (${this.candidateConnection.tensionUOM})`;
    subtitleValues[8] = `Pressure Load (${this.candidateConnection.pressureUOM})`;
    subtitleValues[10] = `Axial Load (${this.candidateConnection.tensionUOM})`;
    subtitleValues[11] = `Pressure Load (${this.candidateConnection.pressureUOM})`;
    worksheet.addRow(subtitleValues);

    // Add style to Subtitle
    worksheet.getCell('A4').alignment = { wrapText: true, horizontal: 'center' };
    worksheet.getCell('A4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
    worksheet.getCell('A4').font = { bold: true };
    worksheet.getCell('B4').alignment = { wrapText: true, horizontal: 'center' };
    worksheet.getCell('B4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
    worksheet.getCell('B4').font = { bold: true };
    worksheet.getCell('D4').alignment = { wrapText: true, horizontal: 'center' };
    worksheet.getCell('D4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
    worksheet.getCell('D4').font = { bold: true };
    worksheet.getCell('E4').alignment = { wrapText: true, horizontal: 'center' };
    worksheet.getCell('E4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
    worksheet.getCell('E4').font = { bold: true };
    worksheet.getCell('G4').alignment = { wrapText: true, horizontal: 'center' };
    worksheet.getCell('G4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
    worksheet.getCell('G4').font = { bold: true };
    worksheet.getCell('H4').alignment = { wrapText: true, horizontal: 'center' };
    worksheet.getCell('H4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
    worksheet.getCell('H4').font = { bold: true };
    worksheet.getCell('J4').alignment = { wrapText: true, horizontal: 'center' };
    worksheet.getCell('J4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
    worksheet.getCell('J4').font = { bold: true };
    worksheet.getCell('K4').alignment = { wrapText: true, horizontal: 'center' };
    worksheet.getCell('K4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
    worksheet.getCell('K4').font = { bold: true };



    //Add Gas/Liquid/Structural values
    let liquidEnvelope: IEnvelope = this.candidateConnection.dbEnvelopes['Liquid'];
    let gasEnvelope: IEnvelope = this.candidateConnection.dbEnvelopes['Gas'];
    let structuralEnvelope: IEnvelope = this.candidateConnection.dbEnvelopes['Structural'];

    let maxLength = Math.max(liquidEnvelope.tension.length, gasEnvelope.tension.length, structuralEnvelope.tension.length);

    for (var i = 0; i < maxLength; i++) {
      let rowValues = [];

      if (gasEnvelope.tension.length >= i) {
        rowValues[1] = gasEnvelope.tension[i];
        rowValues[2] = gasEnvelope.pressure[i];
      }

      if (liquidEnvelope.tension.length >= i) {
        rowValues[4] = liquidEnvelope.tension[i];
        rowValues[5] = liquidEnvelope.pressure[i];
      }

      if (structuralEnvelope.tension.length >= i) {
        rowValues[7] = structuralEnvelope.tension[i];
        rowValues[8] = structuralEnvelope.pressure[i];
      }
      worksheet.addRow(rowValues);
    }

    this.configureBorder(worksheet, maxLength);

    if (!isTest) {
      this.exportDataToExcel(this.workbook);
    }

  }

  configureBorder(worksheet, maxLength) {
    this.createOuterBorder(
      worksheet, { row: 3, col: 1 }, { row: maxLength + 4, col: 1 }, 'thin');
    this.createOuterBorder(
      worksheet, { row: 3, col: 1 }, { row: maxLength + 4, col: 2 });

    this.createOuterBorder(
      worksheet, { row: 3, col: 4 }, { row: maxLength + 4, col: 4 }, 'thin');
    this.createOuterBorder(
      worksheet, { row: 3, col: 4 }, { row: maxLength + 4, col: 5 });

    this.createOuterBorder(
      worksheet, { row: 3, col: 7 }, { row: maxLength + 4, col: 7 }, 'thin');
    this.createOuterBorder(
      worksheet, { row: 3, col: 7 }, { row: maxLength + 4, col: 8 });

    this.createOuterBorder(
      worksheet, { row: 3, col: 10 }, { row: maxLength + 4, col: 10 }, 'thin');
    this.createOuterBorder(
      worksheet, { row: 3, col: 10 }, { row: maxLength + 4, col: 11 });

  }

  createOuterBorder = (worksheet, start = { row: 1, col: 1 }, end = { row: 1, col: 1 }, borderWidth = 'medium') => {

    const borderStyle = {
      style: borderWidth
    };
    for (let i = start.row; i <= end.row; i++) {
      const leftBorderCell = worksheet.getCell(i, start.col);
      const rightBorderCell = worksheet.getCell(i, end.col);
      leftBorderCell.border = {
        ...leftBorderCell.border,
        left: borderStyle
      };
      rightBorderCell.border = {
        ...rightBorderCell.border,
        right: borderStyle
      };
    }

    for (let i = start.col; i <= end.col; i++) {
      const topBorderCell = worksheet.getCell(start.row, i);
      const bottomBorderCell = worksheet.getCell(end.row, i);
      topBorderCell.border = {
        ...topBorderCell.border,
        top: borderStyle
      };
      bottomBorderCell.border = {
        ...bottomBorderCell.border,
        bottom: borderStyle
      };
    }
  };

  createWatermark() {
    this.candidateConnection.evaluationStatus

    const image = new Image();

    switch (this.candidateConnection.evaluationStatus) {
      case 'Fully Evaluated':
        image.src = '../../../../../assets/images/FullyEvaluatedWatermark.png';
        break;
      case 'Fully Evaluated Limited Sealability Envelope':
        image.src = '../../../../../assets/images/FullyEvaluatedWatermark.png';
        break;
      case 'FEA Only':
        image.src = '../../../../../assets/images/FEAOnlyWatermark.png';
        break;
      case 'Partially Evaluated':
        image.src = '../../../../../assets/images/PartiallyEvaluatedWatermark.png';
        break;
      default:
    }

    const imagebgPlugin = {
      id: 'customCanvasBackgroundImage',
      beforeDraw: (chart) => {
        if (image.complete) {
          const ctx = chart.ctx;
          const { top, left, width, height } = chart.chartArea;
          const x = left + width / 2 - image.width / 2;
          const y = top + height / 2 - image.height / 2;
          ctx.drawImage(image, x, y);
        } else {
          image.onload = () => chart.draw();
        }
      }
    };

    Chart.unregister(imagebgPlugin);
    Chart.register(imagebgPlugin);

    if (this.liquidChart && this.structuralChart && this.gasChart) {
      this.liquidChart.update();
      this.structuralChart.update();
      this.gasChart.update();
    }
  }

  getWallThicknessOrHighCollapse(annotations, sealabilityType) {
    if (this.candidateConnection && this.candidateConnection.dbEnvelopes) {
      let sealabilityEnvelope: IEnvelope = this.candidateConnection.dbEnvelopes[sealabilityType];
      if (sealabilityEnvelope && (this.candidateConnection.isHighCollapse || this.candidateConnection.isWallThickness90)) {
        annotations.push({
          type: 'label',
          display: true,
          xValue: sealabilityEnvelope.maxX - (sealabilityEnvelope.maxX / 2),
          yValue: sealabilityEnvelope.minY - (sealabilityEnvelope.minY / 5),
          backgroundColor: 'rgba(211,211,211,100)',
          content: ['Pipe envelopes reflect nominal properties.', 'Connection envelopes are accurate for higher performance pipe.'],
          font: {
            size: 11
          },
          color: 'black',
          textAlign: 'center',
          padding: 10
        });
      }
    }
  }


  saveConnection(): void {

    const detailsSectionData: IConnectionRequest = this.detailsSectionComponent.getConnectionDetailFormData;
    const testInformationData = this.testInformationSectionComponent.getTestInforamationData;

    const connectionPerformanceData = this.uploadeUtilityService.getConnectionPerformanceData(this.connectionPerformanceSectionComponent.connectionInformationColumnsData);
    detailsSectionData.TestInformationDTOs = testInformationData;
    detailsSectionData.ConnectionPerformanceDTOs = connectionPerformanceData;
    detailsSectionData.UOM = this.unitMeasure.getUnitOfMeasure();
    detailsSectionData.CNCT_ID = this.candidateConnection.connectionId ? +this.candidateConnection.connectionId : null;
    detailsSectionData.UserName = this._msalService.instance.getAllAccounts()[0]?.name;
    detailsSectionData.VTableDependents = this.detailsSectionComponent?.vTableDependents;
    if (this.validateData(detailsSectionData)) {
      this.uploadeUtilityService.saveConnection(detailsSectionData).subscribe(x => {
        this.successMessage = 'Connection data is saved successfully';
        this.errorAlertService.errorMessage.next('');
        this.isDataSaved = true;
        this.isUploadeUtility = false;
        this.candidateConnection = x[0];
        this.tabList.map(x => x.isHeaderDisabled = false);
        setTimeout(() => {
          this.connectionPerformanceSectionComponent?.setConnectonPRMs(this.candidateConnection?.connectionPerformances);
        }, 50);
        this.updateCustomLogicFields();
        this.drawCharts();
        this.candidateConnection.manufacturerLogo = this.uploadeUtilityService.getManufacturerLogo(this.candidateConnection.manufacturer);
        this.uploadeUtilityService.isTableColumnsEditable.next(false);
        this.uploadeUtilityService.showCopyExcel.next(false);
        this.uploadeUtilityService.showAddButton.next(false);
        this.uploadeUtilityService.isConnectionEdit =this.isConnectionEdit = false;
        this.isConnectionModified = false;
        this.unitMeasure.MetricDisabled.next(false);
      }, (_error) => {
        this.showErrorMessage();
      });
      this.uploadeUtilityService.isManualEntry = false;
    } else {
      this.errorAlertService.errorMessage.next('Make sure all mandatory parameters (*) are available');
    }

  }

  public showErrorMessage() {
    this.showConfirmationModal = false;
    this.errorAlertService.errorMessage.next('An unknown error has occurred. Contact goto/WECsupport for assistance if the problem persists.');
  }

  public validateData(inputData: any): boolean {
    let isFormValid = true;

    //for validating the input fields
    isFormValid = this.detailsSectionComponent?.detailsForm.valid;

    if (!isFormValid) return isFormValid;

    //Test information tab
    for (var key of this.testInfoFields) {
      for (var item of inputData.TestInformationDTOs) {
        if (item[key] === null || item[key] === undefined || item[key].toString().trim() === '') {
          return isFormValid = false;
        }
      }
    }

    //Connection Performance tab
    for (var key of this.connPerfFields) {
      for (var item of inputData.ConnectionPerformanceDTOs) {
        if (item[key] === null || item[key] === undefined || item[key].toString().trim() === '') {
          return isFormValid = false;
        }
      }
    }

    return isFormValid;
  }

  private updateCustomLogicFields() {
    // const [day, month, year] = this.candidateConnection.lastUpdated.split('/');
    // const formattedDate = `${year.length === 2 ? '20' + year : year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    this.detailsSectionComponent.updateCustomLogicFields({
      gasEnvelopePresence: this.candidateConnection.gasEnvelopePresence,
      material: this.candidateConnection.material,
      connectionUse: this.candidateConnection.connectionUse,
      connectionDocuments: this.candidateConnection.connectionDocuments,
      evaluationStatus: this.candidateConnection.evaluationStatus,
      evaluationMethod: this.candidateConnection.evaluationMethod,
      lastUpdated: this.candidateConnection.lastUpdated,
      variableLengthBox:  this.candidateConnection.variableLengthBox
    });
  }

  deleteConnection() {
    this.showConfirmationModal = true;
  }

  deleteConnectionOnConfirmation(isDeleteConfimed: boolean) {
    if (isDeleteConfimed) {
      this.uploadeUtilityService.deleteConnection(+this.candidateConnection?.connectionId).subscribe((response: any) => {
        if (response) {
          this.showConfirmationModal = false;
          this.successMessage = 'Connection is deleted successfully';

          timer(1000).subscribe(() => {
            this.onBackClick(true);
          });
        } else {
          this.showErrorMessage();
        }
      }, (_error) => {
        this.showErrorMessage();
      });
    } else {
      this.showConfirmationModal = false;
    }
  }

  closeSuccessMessage(): void {
    this.successMessage = '';
  }

  isConnectionModified;
  isTableColumnsEditable: boolean = true;
  isConnectionEdit: boolean;
  showConfirmationModal: boolean = false;
  deleteConnectionConstants = DeleteConnection;

  discarded() {
    if (this.candidateConnection.connectionId != undefined) {
      this.editConnection();
      this.connectionPerformanceSectionComponent.setConnectonPRMs(this.candidateConnection.connectionPerformances);
    }
    else {
      if (this.uploadeUtilityService.isCSEUpload == true && this.detailsSectionComponent.getDetailsSectionLocalStorage() != null) {
        // reset the detail section to previous state
        this.detailsSectionComponent?.detailsForm?.reset(this.detailsSectionComponent.getDetailsSectionLocalStorage());
      }
      if (this.uploadeUtilityService.isCSEUpload == false) {
        this.detailsSectionComponent?.detailsForm?.reset([]);
      }
      // reset the test information state
      this.testInformationSectionComponent.discard(this.isConnectionEdit);
      // reset tge connection performance state
      this.connectionPerformanceSectionComponent.discard();
    }
    this.isConnectionModified = false;
    this.uploadeUtilityService.isManualEntry = true;

  }



  editConnection() {
    this.uploadeUtilityService.isConnectionEdit=this.isConnectionEdit = true;
    this._searchCriteriaService.searchWithAdditionalInfoForUU([this.candidateConnection.connectionId]).subscribe((responseData) => {
      this.candidateConnection = responseData[0];
      this.candidateConnection.manufacturerLogo = this.uploadeUtilityService.getManufacturerLogo(this.candidateConnection.manufacturer);
      setTimeout(() => {
        this.detailsSectionComponent?.setFormValues(this.candidateConnection);
        this.updateCustomLogicFields();
      }, 50);
      this.uploadeUtilityService.isTableColumnsEditable.next(true);
      this.uploadeUtilityService.showCopyExcel.next(true);
      this.uploadeUtilityService.showAddButton.next(true);
      setTimeout(() => {
        this.testInformationSectionComponent?.edit(this.candidateConnection?.testSpecimens);
      }, 50);
    });
   
    //this.uploadeUtilityService.connectionPeformanceTablePrepared.next(true);

  }


}

