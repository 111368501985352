import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { UnitOfMeasureService } from "../../../../service/tcdb/unitofmeasure.service";
import { MainHeaderService } from "../../../../service/tcdb/mainheader.service";
import { Constants } from "../../../../common/constants/constant";
import { IExpandableMenuItem } from "../../../../common/models/components/iExpandableMenuItem";
import { SearchCriteriaService } from "../../../../service/tcdb/searchcriteria.service";
import { LoadCaseService } from "../../../../service/tcdb/loadcaseservice";
import { Router } from "@angular/router";
import { TabStorageService } from "src/app/service/tcdb/tabstorage.service";

@Component({
  selector: "app-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.css"],
})
export class MainHeaderComponent {
  userName: string;
  unitOfMeasureList: IExpandableMenuItem[] = [
    { name: Constants.unitUS },
    { name: Constants.unitMetric, isDisabled: false },
  ];
  helpList = [
    { name: Constants.userGuide, url: Constants.urlWiki },
    { name: Constants.loadExportTemplate },
    { name: Constants.contactUs, url: Constants.urlSnow },
  ];
  unitOfMeasureTitle = Constants.unitTitle;
  4;
  helpTitle = Constants.helpTitle;
  countOfLoads = 0;
  constructor(
    private _msalService: MsalService,
    private unitOfMeasureService: UnitOfMeasureService,
    private _mainHeaderService: MainHeaderService,
    private searchCriteria: SearchCriteriaService,
    private loadCaseService: LoadCaseService,
    private router: Router,
    public _tabStorageService: TabStorageService,
    private cdr: ChangeDetectorRef
  ) {
    const account = this._msalService.instance.getAllAccounts()[0];
    this.userName = account.name;
    this._mainHeaderService.GetLoadCount().subscribe((count) => {
      this.countOfLoads = count;
    });
    if (this.router.url === "/search") {
      localStorage.setItem("AddNewConnection", "");
    }
    this.unitOfMeasureService.MetricDisabled.subscribe((x) => {
      x ? this.setMetricDisabled(true) : this.setMetricDisabled(false);
    });
  }

  private setMetricDisabled(disableMetric: boolean) {
    if (disableMetric) {
      this.onUnitOfMeasureClick(Constants.unitUS);
    }
    this.unitOfMeasureList = [
      { name: Constants.unitUS },
      { name: Constants.unitMetric, isDisabled: disableMetric },
    ];
    this.unitOfMeasureList = [...this.unitOfMeasureList];
    this.cdr.markForCheck();
  }

  onUnitOfMeasureClick(value) {
    //clear the saved filters and expansion panels list before saving the Unit change.
    this.searchCriteria.removeFilters();
    this.unitOfMeasureService.setUnitOfMeasure(value, true);
    //convert the locally stored loads when UoM is changed.
    this.loadCaseService.convertStoredLoads();
    localStorage.setItem("AddNewConnection", "");
  }

  getCheckedChild() {
    return this.unitOfMeasureService.getUnitOfMeasure();
  }

  updateMenuSelected(selected) {
    localStorage.setItem("AddNewConnection", "");
    localStorage.setItem("MenuSelected", selected);
  }

  getMenuSelected() {
    return localStorage.getItem("MenuSelected");
  }

  logout() {
    localStorage.setItem("AddNewConnection", "");
    this._msalService.logout();
  }
  onAddNewConnection() {
    localStorage.setItem("AddNewConnection", "true");
  }
}
