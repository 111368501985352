<div style="display:none; font-size: 12px" *ngIf="candidateConnection">
  <div id="htmlDataDetails">
    <div>
      <div style="text-align:left;"> <span>Generated: {{currentDateTime}} by TCDB Web App</span> </div>
      <div style="text-align:right;"> <span> User: {{userName}}</span> </div>
    </div>

    <div>
      <table style="padding:10px">
        <tr>
          <th colspan="30"> <h4 style="text-align:center;">  Connection Datasheet and Evaluation Summary  </h4></th>
        </tr>
        <tr rowspan="2">
          <td colspan="6"><b>Manufacturer:</b><br />{{candidateConnection.manufacturer}}</td>
          <td colspan="6"><b>Connection:</b><br /> {{candidateConnection.connectionName}}</td>
          <td colspan="5"><b>Size:</b><br /> {{candidateConnection.od}} {{candidateConnection.odUOM}} </td>
          <td colspan="4"><b>Weight:</b><br /> {{candidateConnection.weight}} {{candidateConnection.weightUOM}}</td>
          <td colspan="5"><b>Grade:</b><br /> {{candidateConnection.gradesReportString}}</td>
          <td colspan="4"><b>ID:</b><br /> {{candidateConnection.connectionId}}</td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr>
          <td colspan="30">
            <b>Drawing numbers:</b>
          </td>
        </tr>
        <tr rowspan="4">
          <td colspan="30">
            Box: {{candidateConnection.box}} <br />
            Pin: {{candidateConnection.pin}}<br />
            Thread: {{candidateConnection.thread}}<br />
            Details: {{candidateConnection.details}}
          </td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="2">
          <td colspan="8">
            <b>Evaluation Status:</b><br />
            {{candidateConnection.evaluationStatus}}
          </td>
          <td colspan="7">
            <b>Evaluation Method:</b><br />
            {{candidateConnection.evaluationMethod}}
          </td>
          <td colspan="8">
            <b>Connection Evaluated For:</b> <br />
            {{candidateConnection.connectionUse}}
          </td>
          <td colspan="7">
            <b>Productline:</b><br />
            {{candidateConnection.productLine}}
          </td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="2">
          <td colspan="7">
            <b> Pipe Wall Thickness: </b><br />
            {{candidateConnection.wallThickness}} {{candidateConnection.wallThicknessUOM}}
          </td>
          <td colspan="8">
            <b> Coupling/Box OD: </b><br />
            {{candidateConnection.couplingBoxOD}} {{candidateConnection.odUOM}}
          </td>
          <td colspan="8">
            <b> Coupling/Box Length: </b> <br />
            {{candidateConnection.couplingBoxLength}} {{candidateConnection.odUOM}}
          </td>
          <td colspan="7">
            <b> Makeup Loss:</b><br />
            {{candidateConnection.makeupLoss}} {{candidateConnection.odUOM}}
          </td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="7">
          <td colspan="10">
            <b> Geometry: </b>
            {{candidateConnection.connectionTypeString}}
          </td>
          <td colspan="10">
            <b> Thread Compound: </b><br />
            {{candidateConnection.threadCompound}}<br />
            <br />
            <br />
            <br />
            <b>Box:</b> {{candidateConnection.threadCompoundBox}} g<br />
            <b>Pin:</b> {{candidateConnection.threadCompoundPin}} g<br />
          </td>
          <td colspan="10">
            <b> Torques (N-m): </b><br />
            <b>Final: </b> MAX:   {{candidateConnection.finalMax}}<br />
            OPT:   {{candidateConnection.finalOptimum}}<br />
            MIN:    {{candidateConnection.finalMin}}<br />
            <b>Shoulder: </b>MAX:    {{candidateConnection.shoulderMax}}<br />
            MIN:    {{candidateConnection.shoulderMin}}<br />
            <b>Yield: </b>   MIN:    {{candidateConnection.yieldMin}}<br />
          </td>
        </tr>
        <tr rowspan="6">
          <td colspan="5">
            <b>Seal Type(s):</b><br />
            {{sealTypesString}}
          </td>
          <td colspan="7">
            <b>Seal Location(s):</b><br />
            {{sealLocationString}}
          </td>
          <td colspan="6">
            <b>Thread Type:</b><br />
            {{candidateConnection.threadType}}<br />
            <b>Thread Step:</b><br />
            {{candidateConnection.multiStep}}
          </td>
          <td colspan="7">
            <b>Thread Geometry:</b> <br />
            {{candidateConnection.threadGeometry}}
          </td>
          <td colspan="5">
            <b>Shoulder Loc:</b><br />
            {{shoulderLocationString}}
          </td>
        </tr>
        <tr rowspan="6">
          <td colspan="20">
            <b>Manufacturer's Ratings:</b>
            <table>
              <tr>
                <td>
                </td>
                <td>
                  <b>Pipe Body</b>
                </td>
                <td>
                  <b>Connection</b>
                </td>
                <td>
                  <b>Efficiency</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Tension:</b>
                </td>
                <td>
                  {{candidateConnection.tensionPipeBody}} {{candidateConnection.tensionUOM}}
                </td>
                <td>
                  {{candidateConnection.tensionConnection}} {{candidateConnection.tensionUOM}}
                </td>
                <td>
                  {{candidateConnection.tensionEfficiency}} %
                </td>
              </tr>
              <tr>
                <td>
                  <b>Compression:</b>
                </td>
                <td>
                  {{candidateConnection.compressionPipebody}} {{candidateConnection.tensionUOM}}
                </td>
                <td>
                  {{candidateConnection.compressionConnection}} {{candidateConnection.tensionUOM}}
                </td>
                <td>
                  {{candidateConnection.compressionEfficiency}} %
                </td>
              </tr>
              <tr>
                <td>
                  <b>Internal Pressure:</b>
                </td>
                <td>
                  {{candidateConnection.internalPressurePipebody}} {{candidateConnection.pressureUOM}}
                </td>
                <td>
                  {{candidateConnection.internalPressurePipebody}} {{candidateConnection.pressureUOM}}
                </td>
                <td>
                  {{candidateConnection.internalPressureEfficiency}} %
                </td>
              </tr>
              <tr>
                <td>
                  <b>External Pressure:</b>
                </td>
                <td>
                  {{candidateConnection.externalPressurePipebody}} {{candidateConnection.pressureUOM}}
                </td>
                <td>
                  {{candidateConnection.externalPressureConnection}} {{candidateConnection.pressureUOM}}
                </td>
                <td>
                  {{candidateConnection.externalPressureEfficiency}} %
                </td>
              </tr>
            </table>
          </td>
          <td colspan="10">
            <b>
              Critical Cross Sectional
              Area ({{candidateConnection.areaUOM}}):
            </b>
            <table>
              <tr>
                <td colspan="10">
                  <b>Pipe Body:</b>
                </td>
                <td colspan="10">
                  {{candidateConnection.criticalCrossSectAreaPipeBody}}
                </td>
              </tr>
              <tr>
                <td colspan="10">
                  <b>Coupling/Box:</b>
                </td>
                <td colspan="10">
                  {{candidateConnection.criticalCrossSectAreaCouplingBox}}
                </td>
              </tr>
              <tr>
                <td colspan="10">
                  <b>Pin:</b>
                </td>
                <td colspan="10">
                  {{candidateConnection.criticalCrossSectAreaPin}}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr rowspan="7">
          <td colspan="17">
            <table>
              <tr>
                <td colspan="30">
                  <b>Surface Finish</b><br />
                  <b>Pin:</b> {{candidateConnection.pinsurfacefinish}}<br />
                  <b>Coupling/Box:</b> {{candidateConnection.couplingsurfacefinish}}<br />
                </td>
              </tr>
              <tr>
                <td colspan="30">
                  <b>URC Document Number</b><br />
                  {{candidateConnection.document}}
                </td>
              </tr>
              <tr>
                <td colspan="30">
                  <b>Date Last Updated</b><br />
                  {{candidateConnection.lastUpdated}}
                </td>
              </tr>
            </table>
          </td>
          <td colspan="13">
            <b>Physical Testing / FEA Comments</b><br />
            {{candidateConnection.testComments}}
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div id="htmlDataLiquid">
    <div style="text-align:left;" class="pdf-pagebreak-before"> <span>Generated: {{currentDateTime}} by TCDB Web App</span> </div>
    <div style="text-align:right;"> <span> User: {{userName}}</span> </div>

    <div>
      <table style="padding:10px">
        <tr>
          <th colspan="30"> <h4 style="text-align:center;">  Connection Datasheet and Evaluation Summary  </h4></th>
        </tr>
        <tr rowspan="2">
          <td colspan="6"><b>Manufacturer:</b><br />{{candidateConnection.manufacturer}}</td>
          <td colspan="6"><b>Connection:</b><br /> {{candidateConnection.connectionName}}</td>
          <td colspan="5"><b>Size:</b><br /> {{candidateConnection.od}} {{candidateConnection.odUOM}} </td>
          <td colspan="4"><b>Weight:</b><br /> {{candidateConnection.weight}} {{candidateConnection.weightUOM}}</td>
          <td colspan="5"><b>Grade:</b><br /> {{candidateConnection.gradesReportString}}</td>
          <td colspan="4"><b>ID:</b><br /> {{candidateConnection.connectionId}}</td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="2">
          <td colspan="16">
            <b>URC Document Number: </b> <br /><span>{{candidateConnection.document}}</span>
          </td>
          <td colspan="14">
            <b>Connection Evaluated For:</b> <br />
            <span>{{candidateConnection.connectionUse}}</span> <br />
          </td>
        </tr>
        <tr rowspan="2">
          <td colspan="16">
            Thread Compound: <br />
            <span><b>{{candidateConnection.threadCompound}}</b></span>
          </td>
          <td colspan="14">
            <b>Evaluation Method:</b><br />
            <span>{{candidateConnection.evaluationMethod}}</span>
          </td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="6">
          <td colspan="16">
            <table>
              <tr>
                <td>Torque (N-m)</td>
                <td>Final</td>
                <td>Shoulder</td>
                <td>Yield</td>
              </tr>
              <tr>
                <td>Maximum</td>
                <td>{{candidateConnection.finalMax}}</td>
                <td>{{candidateConnection.shoulderMax}}</td>
                <td style="background-color:grey">    </td>
              </tr>
              <tr>
                <td>Optimum</td>
                <td>{{candidateConnection.finalOptimum}}</td>
                <td style="background-color:grey"></td>
                <td style="background-color:grey">    </td>
              </tr>
              <tr>
                <td>Minimum</td>
                <td>{{candidateConnection.finalMin}}</td>
                <td>{{candidateConnection.shoulderMin}}</td>
                <td>{{candidateConnection.yieldMin}}</td>
              </tr>
            </table>
            <table>
              <tr>
                <td>
                  Surface Finish
                </td>
                <td>
                  Pin: {{candidateConnection.pinsurfacefinish}}<br />
                  Box: {{candidateConnection.couplingsurfacefinish}}
                </td>
              </tr>
            </table>
          </td>
          <td colspan="14">
            Manufacturer's Ratings<br />
            <table>
              <tr>
                <td>
                </td>
                <td>
                  Pipe Body
                </td>
                <td>
                  Connection
                </td>
              </tr>
              <tr>
                <td>
                  Tension
                </td>
                <td>
                  <b> {{candidateConnection.tensionPipeBody}} {{candidateConnection.tensionUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.tensionConnection}} {{candidateConnection.tensionUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Compression
                </td>
                <td>
                  <b> {{candidateConnection.compressionPipebody}} {{candidateConnection.tensionUOM}}</b>
                </td>
                <td>
                  <b>{{candidateConnection.compressionConnection}} {{candidateConnection.tensionUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Internal Pressure
                </td>
                <td>
                  <b>{{candidateConnection.internalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.internalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  External Pressure
                </td>
                <td>
                  <b> {{candidateConnection.externalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.externalPressureConnection}} {{candidateConnection.pressureUOM}}</b>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="20">
          <td colspan="30">
            <br />
            <h6 style="text-align:center;"> Liquid Sealability Envelope </h6><br />
            <span style="text-align:center;">{{section_liquid}} - {{candidateConnection.manufacturer}} {{candidateConnection.connectionName}} - OD: {{candidateConnection.od}} {{candidateConnection.odUOM}}, WEIGHT: {{candidateConnection.weight}} {{candidateConnection.weightUOM}}, YIELD : {{candidateConnection.yield}} {{candidateConnection.yieldUOM}} </span> <br /><br />
            <img src={{liquidChartToPDF}} alt="liquid chart" width="650" height="400" /> <br />
          </td>
        </tr>

      </table>
    </div>
  </div>
  <div id="htmlDataGas">
    <div style="text-align:left;" class="pdf-pagebreak-before"> <span>Generated: {{currentDateTime}} by TCDB Web App</span> </div>
    <div style="text-align:right;"> <span> User: {{userName}}</span> </div>

    <div>
      <table style="padding:10px">
        <tr>
          <th colspan="30"> <h4 style="text-align:center;">  Connection Datasheet and Evaluation Summary  </h4></th>
        </tr>
        <tr rowspan="2">
          <td colspan="6"><b>Manufacturer:</b><br />{{candidateConnection.manufacturer}}</td>
          <td colspan="6"><b>Connection:</b><br /> {{candidateConnection.connectionName}}</td>
          <td colspan="5"><b>Size:</b><br /> {{candidateConnection.od}} {{candidateConnection.odUOM}} </td>
          <td colspan="4"><b>Weight:</b><br /> {{candidateConnection.weight}} {{candidateConnection.weightUOM}}</td>
          <td colspan="5"><b>Grade:</b><br /> {{candidateConnection.gradesReportString}}</td>
          <td colspan="4"><b>ID:</b><br /> {{candidateConnection.connectionId}}</td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="2">
          <td colspan="16">
            <b>URC Document Number: </b> <br /><span>{{candidateConnection.document}}</span>
          </td>
          <td colspan="14">
            <b>Connection Evaluated For:</b> <br />
            <span>{{candidateConnection.connectionUse}}</span> <br />
          </td>
        </tr>
        <tr rowspan="2">
          <td colspan="16">
            Thread Compound: <br />
            <span><b>{{candidateConnection.threadCompound}}</b></span>
          </td>
          <td colspan="14">
            <b>Evaluation Method:</b><br />
            <span>{{candidateConnection.evaluationMethod}}</span>
          </td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="6">
          <td colspan="16">
            <table>
              <tr>
                <td>Torque (N-m)</td>
                <td>Final</td>
                <td>Shoulder</td>
                <td>Yield</td>
              </tr>
              <tr>
                <td>Maximum</td>
                <td>{{candidateConnection.finalMax}}</td>
                <td>{{candidateConnection.shoulderMax}}</td>
                <td style="background-color:grey">    </td>
              </tr>
              <tr>
                <td>Optimum</td>
                <td>{{candidateConnection.finalOptimum}}</td>
                <td style="background-color:grey"></td>
                <td style="background-color:grey">    </td>
              </tr>
              <tr>
                <td>Minimum</td>
                <td>{{candidateConnection.finalMin}}</td>
                <td>{{candidateConnection.shoulderMin}}</td>
                <td>{{candidateConnection.yieldMin}}</td>
              </tr>
            </table>
            <table>
              <tr>
                <td>
                  Surface Finish
                </td>
                <td>
                  Pin: {{candidateConnection.pinsurfacefinish}}<br />
                  Box: {{candidateConnection.couplingsurfacefinish}}
                </td>
              </tr>
            </table>
          </td>
          <td colspan="14">
            Manufacturer's Ratings<br />
            <table>
              <tr>
                <td>
                </td>
                <td>
                  Pipe Body
                </td>
                <td>
                  Connection
                </td>
              </tr>
              <tr>
                <td>
                  Tension
                </td>
                <td>
                  <b> {{candidateConnection.tensionPipeBody}} {{candidateConnection.tensionUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.tensionConnection}} {{candidateConnection.tensionUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Compression
                </td>
                <td>
                  <b> {{candidateConnection.compressionPipebody}} {{candidateConnection.tensionUOM}}</b>
                </td>
                <td>
                  <b>{{candidateConnection.compressionConnection}} {{candidateConnection.tensionUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Internal Pressure
                </td>
                <td>
                  <b>{{candidateConnection.internalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.internalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  External Pressure
                </td>
                <td>
                  <b> {{candidateConnection.externalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.externalPressureConnection}} {{candidateConnection.pressureUOM}}</b>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr rowspan="20">
          <td colspan="30">
            <br />
            <h6 style="text-align:center;">Gas & Liquid Sealability Envelope </h6><br />
            <span style="text-align:center;">{{section_gas}} - {{candidateConnection.manufacturer}} {{candidateConnection.connectionName}} - OD: {{candidateConnection.od}} {{candidateConnection.odUOM}}, WEIGHT: {{candidateConnection.weight}} {{candidateConnection.weightUOM}}, YIELD : {{candidateConnection.yield}} {{candidateConnection.yieldUOM}} </span> <br /><br />
            <img src={{gasChartToPDF}} alt="gas chart" width="650" height="400" /><br />
          </td>
        </tr>

      </table>
    </div>
  </div>
  <div id="htmlDataStructural">
    <div style="text-align:left;" class="pdf-pagebreak-before"> <span>Generated: {{currentDateTime}} by TCDB Web App</span> </div>
    <div style="text-align:right;"> <span> User: {{userName}}</span> </div>

    <div>
      <table style="padding:10px">
        <tr>
          <th colspan="30"> <h4 style="text-align:center;">  Connection Datasheet and Evaluation Summary  </h4></th>
        </tr>
        <tr rowspan="2">
          <td colspan="6"><b>Manufacturer:</b><br />{{candidateConnection.manufacturer}}</td>
          <td colspan="6"><b>Connection:</b><br /> {{candidateConnection.connectionName}}</td>
          <td colspan="5"><b>Size:</b><br /> {{candidateConnection.od}} {{candidateConnection.odUOM}} </td>
          <td colspan="4"><b>Weight:</b><br /> {{candidateConnection.weight}} {{candidateConnection.weightUOM}}</td>
          <td colspan="5"><b>Grade:</b><br /> {{candidateConnection.gradesReportString}}</td>
          <td colspan="4"><b>ID:</b><br /> {{candidateConnection.connectionId}}</td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="2">
          <td colspan="16">
            <b>URC Document Number: </b> <br /><span>{{candidateConnection.document}}</span>
          </td>
          <td colspan="14">
            <b>Connection Evaluated For:</b> <br />
            <span>{{candidateConnection.connectionUse}}</span> <br />
          </td>
        </tr>
        <tr rowspan="2">
          <td colspan="16">
            Thread Compound: <br />
            <span><b>{{candidateConnection.threadCompound}}</b></span>
          </td>
          <td colspan="14">
            <b>Evaluation Method:</b><br />
            <span>{{candidateConnection.evaluationMethod}}</span>
          </td>
        </tr>
        <tr>
          <td colspan="30"></td>
        </tr>
        <tr rowspan="6">
          <td colspan="16">
            <table width="100%">
              <tr>
                <td colspan="3">Torque (N-m)</td>
                <td colspan="3">Final</td>
                <td colspan="3">Shoulder</td>
                <td colspan="3">Yield</td>
              </tr>
              <tr>
                <td colspan="3">Maximum</td>
                <td colspan="3">{{candidateConnection.finalMax}}</td>
                <td colspan="3">{{candidateConnection.shoulderMax}}</td>
                <td style="background-color:grey" colspan="3">    </td>
              </tr>
              <tr>
                <td colspan="3">Optimum</td>
                <td colspan="3">{{candidateConnection.finalOptimum}}</td>
                <td colspan="3" style="background-color:grey"></td>
                <td colspan="3" style="background-color:grey">    </td>
              </tr>
              <tr>
                <td colspan="3">Minimum</td>
                <td colspan="3">{{candidateConnection.finalMin}}</td>
                <td colspan="3">{{candidateConnection.shoulderMin}}</td>
                <td colspan="3">{{candidateConnection.yieldMin}}</td>
              </tr>
            </table>
            <table width="100%">
              <tr>
                <td>
                  Surface Finish
                </td>
                <td>
                  Pin: {{candidateConnection.pinsurfacefinish}}<br />
                  Box: {{candidateConnection.couplingsurfacefinish}}
                </td>
              </tr>
            </table>
          </td>
          <td colspan="14">
            Manufacturer's Ratings<br />
            <table>
              <tr>
                <td>
                </td>
                <td>
                  Pipe Body
                </td>
                <td>
                  Connection
                </td>
              </tr>
              <tr>
                <td>
                  Tension
                </td>
                <td>
                  <b> {{candidateConnection.tensionPipeBody}} {{candidateConnection.tensionUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.tensionConnection}} {{candidateConnection.tensionUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Compression
                </td>
                <td>
                  <b> {{candidateConnection.compressionPipebody}} {{candidateConnection.tensionUOM}}</b>
                </td>
                <td>
                  <b>{{candidateConnection.compressionConnection}} {{candidateConnection.tensionUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Internal Pressure
                </td>
                <td>
                  <b>{{candidateConnection.internalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.internalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
              </tr>
              <tr>
                <td>
                  External Pressure
                </td>
                <td>
                  <b> {{candidateConnection.externalPressurePipebody}} {{candidateConnection.pressureUOM}}</b>
                </td>
                <td>
                  <b> {{candidateConnection.externalPressureConnection}} {{candidateConnection.pressureUOM}}</b>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr rowspan="20">
          <td colspan="30">
            <br />
            <h6 style="text-align:center;"> Structural Envelope </h6><br />
            <span style="text-align:center;">{{section_structure}} - {{candidateConnection.manufacturer}} {{candidateConnection.connectionName}} - OD: {{candidateConnection.od}} {{candidateConnection.odUOM}}, WEIGHT: {{candidateConnection.weight}} {{candidateConnection.weightUOM}}, YIELD : {{candidateConnection.yield}} {{candidateConnection.yieldUOM}} </span> <br /><br />
            <img src={{structuralChartToPDF}} alt="structural chart" width="650" height="400" />
            <br />
          </td>
        </tr>

      </table>
    </div>
  </div>
  
  <div id="htmlCaseDescription" style="width:100%">
    <div style="text-align:left;" class="pdf-pagebreak-before"> <span>Generated: {{currentDateTime}} by TCDB Web App</span> </div>
    <div style="text-align:right;"> <span> User: {{userName}}</span> </div>
    <table style="width:100%">
      <thead>
        <tr>
          <th>
            <h4 style="text-align: center; width: 100%">Connection Datasheet and Evaluation Summary</h4>
          </th>
        </tr>
      </thead>
      <tr>
        <td>Case Description :<br /><br/>{{caseDescriptionPDF}}</td>
      </tr>
    </table>    
  </div>
    <div id="htmlCaseSectionArray">
      <div style="text-align:left;" class="pdf-pagebreak-before"> <span>Generated: {{currentDateTime}} by TCDB Web App</span> </div>
      <div style="text-align:right;"> <span> User: {{userName}}</span> </div>

      <div>
        <table style="padding:10px">
          <thead>
            <tr>
              <th>
                <h4 style="text-align:center;">Connection Datasheet and Evaluation Summary</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sectionArray of sectionArrayList">
              <td>
                <table>
                  <!-- tr of loads -->
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:0:2; let i = index">Load Case {{i+1}}<br>{{load.name}}</td>
                    <!--<td>load 2</td>-->
                  </tr>
                  <!-- tr of sections -->
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:0:2">{{sectionArray.name}}</td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:0:2">Comment:<br>{{load.comment}}</td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:0:2">
                      <div class="horizontal-list">
                        Load case plotted on: <br>
                        <span *ngFor="let data of getflattenedArray(load.name)" class="item">
                          {{data}}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:0:2">
                      <div style="width:100%">
                        <table>
                          <tr>
                            <td>
                              Pressure({{unitOfPressure}})
                            </td>
                            <td>
                              Tension({{unitOfTension}})
                            </td>
                          </tr>
                          <tr *ngFor="let data of getPressFromList(load.name)">
                            <td>
                              {{data["pressure"]}}
                            </td>
                            <td>
                              {{data["tension"]}}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>

                </table>
                <table *ngIf="sectionArray.loadArray.length>2">
                  <!-- tr of loads -->
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:2:4; let i = index">Load Case {{i+3}}<br>{{load.name}}</td>
                    <!--<td>load 2</td>-->
                  </tr>
                  <!-- tr of sections -->
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:2:4">{{sectionArray.name}}</td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:2:4">Comment:<br>{{load.comment}}</td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:2:4">
                      <div class="horizontal-list">
                        Load case plotted on: <br>
                        <span *ngFor="let data of getflattenedArray(load.name)" class="item">
                          {{data}}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:2:4">
                      <div style="width:100%">
                        <table>
                          <tr>
                            <td>
                              Pressure({{unitOfPressure}})
                            </td>
                            <td>
                              Tension({{unitOfTension}})
                            </td>
                          </tr>
                          <tr *ngFor="let data of getPressFromList(load.name)">
                            <td>
                              {{data["pressure"]}}
                            </td>
                            <td>
                              {{data["tension"]}}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </table>
                <table *ngIf="sectionArray.loadArray.length>4">
                  <!-- tr of loads -->
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:4:6;let i = index">Load Case {{i+5}}<br>{{load.name}}</td>
                    <!--<td>load 2</td>-->
                  </tr>
                  <!-- tr of sections -->
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:4:6">{{sectionArray.name}}</td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:4:6">Comment:<br>{{load.comment}}</td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:4:6">
                      <div class="horizontal-list">
                        Load case plotted on: <br>
                        <span *ngFor="let data of getflattenedArray(load.name)" class="item">
                          {{data}}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td *ngFor="let load of sectionArray.loadArray | slice:4:6">
                      <div style="width:100%">
                        <table>
                          <tr>
                            <td>
                              Pressure({{unitOfPressure}})
                            </td>
                            <td>
                              Tension({{unitOfTension}})
                            </td>
                          </tr>
                          <tr *ngFor="let data of getPressFromList(load.name)">
                            <td>
                              {{data["pressure"]}}
                            </td>
                            <td>
                              {{data["tension"]}}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
