<div style="padding-left:20px">
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        General
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell details-headers">
                      Manufacturer
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.manufacturer}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Connection Name
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.connectionName}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Connection Type
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.connectionTypeString}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Weight
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.weight}} {{detailsList.weightUOM}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Grade
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.gradesReportString}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Gas Envelope
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.gasEnvelopePresence}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Active
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.active}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Document Number
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2"
                      *ngIf="detailsList.documentLink">
                      <a href={{detailsList.documentLink}} target="_blank" style="text-decoration:none;">
                        {{detailsList.document}}</a>
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2"
                      *ngIf="!detailsList.documentLink">
                      {{detailsList.document}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Stewarding Company
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.stewardingCompany}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Last Updated
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.lastUpdated}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Modified By
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.sealShoulderInformation_ModifiedBy}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Pipe Details
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Size (OD)
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.od}} {{detailsList.odUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Wall Thickness
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.wallThickness}} {{detailsList.wallThicknessUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Yield
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.yield}} {{detailsList.yieldUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Material
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.material}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      CRA Flag
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.craFlag}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Drawing Numbers
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Box
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.box}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Pin
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.pin}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Thread
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.thread}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Details
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.details}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Connection Details
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Coupling/Box OD
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.couplingBoxOD}} {{detailsList.odUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Coupling/Box Length
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.couplingBoxLength}} {{detailsList.odUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Make-Up Loss
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.makeupLoss}} {{detailsList.odUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Connection Use
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.connectionUse}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Thread Compound Comment
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.threadCompoundComment}}
                    </td>
                  </tr>                  
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Pipe Critical Cross-Section
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.criticalCrossSectAreaPipeBody}} {{detailsList.areaUOM}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Variable Length Box
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.variableLengthBox}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Box Critical Cross-Section
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.criticalCrossSectAreaCouplingBox}} {{detailsList.areaUOM}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Pin Critical Cross-Section
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.criticalCrossSectAreaPin}} {{detailsList.areaUOM}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Thread Geometry Type
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.threadGeometry}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Thread Type
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.threadType}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Multi-Step Thread
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.multiStep}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Use Default Folder
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.useDefaultFolder}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Connection Document Link
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.connectionDocuments}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>

  <!-- Test Facts -->
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Test Facts
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div *ngIf="tabStorageService.adminUser" class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Connection Analysis Date
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.connectionAnalysisDate}}
                    </td>
                  </tr>                  
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Tubing Qualification
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.tubingQualificationFlg}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Temperature Rating
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.temperatureRating}} {{detailsList.temperatureUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      M&B Cycles
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.mbCycle}}
                    </td>
                  </tr>                  
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Internal Quench Test
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.internalQuenchTest}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      API Pressure Rating
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.apiPressureRating}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Seal Test
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.sealTest}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      External Seal Test
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.externalSealTest}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      External Pressure Test
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.externalQuenchTest}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Thermal Cycle Test
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.thermalCycleTest}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Structural Test
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.structuralTest}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Surface Finish
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Pin
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.pinsurfacefinish}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Coupling/Box
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.couplingsurfacefinish}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Pin Thread Compound Weight
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.threadCompoundPin}}
                      <span> g</span>
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Box Thread Compound Weight
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.threadCompoundBox}}
                      <span> g</span>
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Evaluation
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Evaluation Status
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.evaluationStatus}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Evaluation Method
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.evaluationMethod}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Productline / Derived Envelope
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.productLine}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Fatigue Evaluation
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.fatigue_Evaluation}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Limited Sealability Envelope
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.limited_Sealability_Envelope}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Bleed Through
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.bleed_Through}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Unconventional
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.unconventional}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      FEA Only
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.feaFlag}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Fully Evaluated
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.fullyEvaluated}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Torques ({{detailsList.torqueUOM}})
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Final Min
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.finalMin}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Final Max
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.finalMax}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Final Optimum
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.finalOptimum}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Shoulder Min
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.shoulderMin}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Shoulder Max
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.shoulderMax}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Yield Torque
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.yieldMin}}
                    </td>
                  </tr>
                  <tr *ngIf="tabStorageService.adminUser" class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      High Torque
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.high_Torque}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Field Torque
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.fieldTorque}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Manufacturer's Rating
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Tension - Pipe Body
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.tensionPipeBody}} {{detailsList.tensionUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Tension - Connection
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.tensionConnection}} {{detailsList.tensionUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Tension Efficiency
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.tensionEfficiency}} %
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Compression - Pipe Body
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.compressionPipebody}} {{detailsList.tensionUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Compression - Connection
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.compressionConnection}} {{detailsList.tensionUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Compression - Efficiency
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.compressionEfficiency}} %
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Internal Pressure - Pipe Body
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.internalPressurePipebody}} {{detailsList.pressureUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Internal Pressure - Connection
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.internalPressureConnection}} {{detailsList.pressureUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Internal Pressure - Efficiency
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.internalPressureEfficiency}} %
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      External Pressure - Pipe Body
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.externalPressurePipebody}} {{detailsList.pressureUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      External Pressure - Connection
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.externalPressureConnection}} {{detailsList.pressureUOM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      External Pressure - Efficiency
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.externalPressureEfficiency}} %
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>

  <!-- Seal and Shoulder Information -->
  <section *ngIf="tabStorageService.adminUser" class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Seal and Shoulder Information
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Seal Type Name
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.seaL_TYPE_NM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Seal Location Name
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.seaL_LOC_NM}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row additional-columns-background">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Shoulder Location Name
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.shldR_LOC_NM}}
                    </td>
                  </tr>
                  <!-- Commenting these lines as we hiding -->
                  <!-- <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Modified By
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.sealShoulderInformation_ModifiedBy}}
                    </td>
                  </tr>
                  <tr class="em-c-table__row ">
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                      Change Date
                    </td>
                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                      {{detailsList.sealShoulderInformation_ChangeDate}}
                    </td>
                  </tr> -->
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
  <section class="em-c-section ">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Test Comments
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row ">

                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="3">
                      {{detailsList.testComments}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>

  <!--  Additional Qualification Comments -->
  <section class="em-c-section " *ngIf="tabStorageService.adminUser">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Additional Qualification Comments
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row additional-columns-background">

                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="3">
                      {{detailsList.additionalQualificationComment}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>

  <!--  Administrative Comments -->
  <section class="em-c-section " *ngIf="tabStorageService.adminUser">
    <header class="em-c-section__header em-c-section__header--underline">
      <h2 class="em-c-section__title ">
        Administrative Comments
        <span class="em-c-section__title-underline"></span>
      </h2>
    </header>
    <!-- end em-c-section-header -->
    <div class="em-c-section__body">
      <div style="padding-left:20px">
        <div class="em-c-table-object ">
          <div class="em-c-table-object__header">
          </div>
          <!--end em-c-table-object__header-->
          <div class="em-c-table-object__body">
            <div class="em-c-table-object__body-inner">
              <table class="em-c-table ">
                <!-- end em-c-table__header -->
                <tbody class="em-c-table__body ">
                  <tr class="em-c-table__row additional-columns-background">

                    <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="3">
                      {{detailsList.administrative_Comments}}
                    </td>
                  </tr>
                  <!-- end em-c-table__row -->
                </tbody>
                <!-- end em-c-table__body -->
                <tfoot class="em-c-table__footer">
                  <tr class="em-c-table__footer-row">
                  </tr>
                </tfoot>
                <!-- end em-c-table__footer -->
              </table>
              <!--end em-c-table-->
            </div>
            <!--end em-c-table-object__body-inner-->
          </div>
          <!--end em-c-table-object__body-->
        </div>
      </div>
      <!--end em-c-table-object-->
      <!--end em-c-table-object-->
    </div>
    <!-- end em-c-section__body -->
  </section>
</div>
<!-- end em-c-section -->
