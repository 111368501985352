<li class="em-c-primary-nav__item">
  <a href="javascript:void(0);" class="em-c-primary-nav__link em-c-primary-nav__link--has-children em-js-nav-dropdown-trigger" aria-expanded="true" aria-selected="true" (click)="collapse()" [ngClass]="isCollapsed ? 'em-is-active' : ''">
    {{title}}
    <svg class="em-c-icon em-c-icon--small em-c-primary-nav__icon">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#caret-down"></use>
    </svg>
  </a><!--end em-c-primary-nav__link-->

  <div class="em-c-primary-nav__sublist navigation-div" aria-hidden="false" aria-selected="true" [ngClass]="isCollapsed ? 'em-is-active' : ''">
    <ul>
      <li class="em-c-primary-nav__subitem" *ngFor="let child of childrenList">
        <div *ngIf="!child.url">
          <div class="em-c-field--inline" *ngIf="isCheckedChild(child.name)">
            <a>
              <svg class="em-c-icon em-c-icon--small em-c-primary-nav__icon">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#check"></use>
              </svg>
            </a>
            <a href="javascript:void(0);" class="em-c-primary-nav__sublink padding-icon" (click)="onChildClick(child.name)">
              {{child.name}}
            </a>
          </div>
          <div>

            <a href="javascript:void(0);"  class="em-c-primary-nav__sublink padding-icon" [ngClass]="child.isDisabled ? 'disable--item' : ''" (click)="onChildClick(child.name)" *ngIf="!isCheckedChild(child.name)">
              {{child.name}}
            </a>
          </div>
        </div>

        <div *ngIf="child.url">
          <a [attr.href]="child.url" target="_blank" class="em-c-primary-nav__sublink padding-icon">
            {{child.name}}
          </a>
        </div>
        <app-export-to-excel [isBlankTemplate]="isBlankExcelTemplate" [sheetList]="excelExport" [fileName]="fileName"></app-export-to-excel>
      </li><!-- end em-c-nav__sublist__item -->
    </ul>
  </div>
  <!-- end em-c-nav__sublist -->
</li>
