import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-save-discard',
  templateUrl: './save-discard.component.html',
  styleUrls: ['./save-discard.component.css']
})
export class SaveDiscardComponent {
  @Output() Saved = new EventEmitter<Boolean>();
  @Output() Discarded = new EventEmitter<Boolean>();
  @Input() DisableDiscard: boolean;
  @Input() SaveDiscard: boolean;
  Save(): void {
    this.Saved.emit(true);
  }

  Discard(): void {
    this.Discarded.emit(true);
  }
}
