import { IDetailSectionControl } from "./IDetailSectionControl";

/*  Test Information Table Constants */
export const TestInformation_Procedure = 'PROCEDURE';
export const TestInformation_Specimen = 'SPECIMEN';
export const TestInformation_MaterialGrade = 'MATERIAL GRADE';
export const TestInformation_Test = 'TEST';
export const TestInformation_Date = 'DATE';
export const TestInformation_Facility = 'FACILITY';
export const TestInformation_Comments = 'COMMENTS';

export const TEST_PROCEDURE = 'TEST_PROCEDURE';
export const SPECIMEN_TYPE = 'SPECIMEN_TYPE';
export const MATERIAL_GRADE = 'MATERIAL_GRADE';
export const TEST_TYPE = 'TEST_TYPE';
export const TEST_DATE = 'TEST_DATE';
export const TEST_FACILITY = 'TEST_FACILITY';
export const COMMENTS = 'COMMENTS';

export const ENVLP_TYPE_CD = 'ENVLP_TYPE_CD';
export const NET_PRSR = 'NET_PRSR';
export const AXIAL_LOAD = 'AXIAL_LOAD';
export const PLOT_SEQ_NBR = 'PLOT_SEQ_NBR';

export const MANUF_VTBL = "MANUF_VTBL";
export const DATA_SRC_VTBL = "DATA_SRC_VTBL";
export const PIPE_GRD_VTBL = "PIPE_GRD_VTBL";
export const THRD_GEOM_VTBL="THRD_GEOM_VTBL";
export const THRD_TYPE_VTBL="THRD_TYPE_VTBL"
export const COAT_TYPE_VTBL = "COAT_TYPE_VTBL";
export const THRD_CMPND_TYPE_NM= "THRD_CMPND_TYPE_NM";
//
export const SEAL_TYPE_VTBL = "SEAL_TYPE_VTBL";
export const SEAL_LOC_VTBL = "SEAL_LOC_VTBL"
export const SHLDR_LOC_VTBL = "SHLDR_LOC_VTBL"

export const CNCT_GEOM_TYPE_VTBL = "CNCT_GEOM_TYPE_VTBL"
export const THRD_CMPND_TYPE_VTBL = "THRD_CMPND_TYPE_VTBL"

/*  Connection Performance Table Constants */
export const ConnectionPerformance_EnvlpType = 'ENVLP_TYPE';
export const ConnectionPerformance_NetPrsr = 'NET PRSR';
export const ConnectionPerformance_AxialLoads = 'AXIAL LOADS';
export const ConnectionPerformance_PlotSeqnbr = 'PLOT SEQNBR';
export const ConnectionPerformance_ModUid = 'MOD UID';
export const ConnectionPerformance_ChangeDate = 'CHANGE DATE';
export const ConnectionPerformance_Comments = 'COMMENTS';

export const TEST_PROCEDURE_VTBLs = 'TEST_PROCEDURE_VTBLs';
export const SPECIMEN_TEST_TYPE_VTBLs = 'SPECIMEN_TEST_TYPE_VTBLs';
export const ENVLP_TYPE_VTBLs = 'ENVLP_TYPE_VTBLs';
export const tesT_PROCEDURE_NM = 'tesT_PROCEDURE_NM';
export const specimeN_TEST_TYPE_NM = 'specimeN_TEST_TYPE_NM';
export const envlP_TYPE_NM = 'envlP_TYPE_NM';
export const envlP_TYPE_CD = 'envlP_TYPE_CD';
export const ENVLP_TYPE_VTBL = 'ENVLP_TYPE_VTBL';


export enum ConnectionPerformacneTable {
    ENVLP_TYPE = 'ENVLP_TYPE',
    NET_PRSR = 'NET PRSR',
    AXIAL_LOAD = 'AXIAL LOADS',
    PLOT_SEQ_NBR = 'PLOT SEQNBR',
    MOD_UID = 'MOD UID',
    CHNG_DATE = 'CHANGE DATE'
}

export const ConnectionPerformance_EnvlpType_Column = 'ENVLP_TYPE_CD';
export const ConnectionPerformance_NetPrsr_Column = 'NET_PRSR';
export const ConnectionPerformance_AxialLoads_Column = 'AXIAL_LOAD';
export const ConnectionPerformance_PlotSeqnbr_Column = 'PLOT_SEQ_NBR';
export const ConnectionPerformance_ModUid_Column = 'MOD_UID';
export const ConnectionPerformance_ChangeDate_Column = 'CHNG_DATE';
export const ConnectionPerformance_Comments_Column = 'COMMENTS';

/* Seal and Shoulder Information Table Constants */
export const SealShoulderInformation_SealType = 'SEAL TYPE';
export const SealShoulderInformation_SealLocation = 'SEAL LOCATION';
export const SealShoulderInformation_ShoulderLocation = 'SHOULDER LOCATION';
export const SealShoulderInformation_ModifiedBy = 'SealShoulderInformation_ModifiedBy';
export const SealShoulderInformation_ChangeDate = 'SealShoulderInformation_ChangeDate';
export const SEAL_TYPE_NM = 'SEAL_TYPE_NM';
export const SEAL_LOC_NM = 'SEAL_LOC_NM';
export const SHLDR_LOC_NM = 'SHLDR_LOC_NM';


/* Connection Description Constants */

export const CNCT_ID = "CNCT_ID";
export const DATA_SRC_NM = "DATA_SRC_NM";
export const NOM_PIPE_OD_DIAM = "NOM_PIPE_OD_DIAM";
export const NOM_PIPE_WT = "NOM_PIPE_WT";
export const NOM_PIPE_WALL_THCK = "NOM_PIPE_WALL_THCK";
export const NOM_PIPE_YLD_STRGTH = "NOM_PIPE_YLD_STRGTH";
export const CRA_FLG = "CRA_FLG";
export const PIPE_TENS_RTNG = "PIPE_TENS_RTNG";
export const PIPE_CMPRS_RTNG = "PIPE_CMPRS_RTNG";
export const PIPE_INTRNL_PRSR_RTNG = "PIPE_INTRNL_PRSR_RTNG";
export const PIPE_EXT_PRSR_RTNG = "PIPE_EXT_PRSR_RTNG";
export const PIPE_CRTC_XSA = "PIPE_CRTC_XSA";
export const CNCT_ANAL_DATE = "CNCT_ANAL_DATE";
export const DRVD_ENVLP_FLG = "DRVD_ENVLP_FLG";
export const MANUF_NM = "MANUF_NM";
export const CNCT_NM = "CNCT_NM";
export const CNCT_GEOM_TYPE_NM = "CNCT_GEOM_TYPE_NM";
export const FULL_CNCT_GRD_ID = "FULL_CNCT_GRD_ID";
export const TBG_QLF_FLG = "TBG_QLF_FLG";
export const NOM_BOX_CPL_OD = "NOM_BOX_CPL_OD";
export const NOM_BOX_CPL_LNG = "NOM_BOX_CPL_LNG";
export const VAR_LNG_BOX_CPL_FLG = "VAR_LNG_BOX_CPL_FLG";
export const MU_LOSS_LEN = "MU_LOSS_LEN";
export const CNCT_TNSN_RTNG = "CNCT_TNSN_RTNG";
export const CNCT_CMPRS_RTNG = "CNCT_CMPRS_RTNG";
export const CNCT_INTRNL_PRSR_RTNG = "CNCT_INTRNL_PRSR_RTNG";
export const CNCT_EXT_PRSR_RTNG = "CNCT_EXT_PRSR_RTNG";
export const BOX_CPL_CRTC_XSA = "BOX_CPL_CRTC_XSA";
export const PIN_CRTC_XSA = "PIN_CRTC_XSA";
export const TEMP_RTNG = "TEMP_RTNG";
export const NOM_TNSL_EFC_PCT = "NOM_TNSL_EFC_PCT";
export const NOM_CMPRS_EFC_PCT = "NOM_CMPRS_EFC_PCT";
export const NOM_INTRNL_PRSR_EFC_PCT = "NOM_INTRNL_PRSR_EFC_PCT";
export const NOM_EXT_PRSR_EFC_PCT = "NOM_EXT_PRSR_EFC_PCT";
export const THRD_GEOM_TYPE_NM = "THRD_GEOM_TYPE_NM";
export const THRD_TYPE_NM = "THRD_TYPE_NM";
export const MULT_STEP_THRD_FLG = "MULT_STEP_THRD_FLG";
export const THRD_CMPND_APPL_TXT = "THRD_CMPND_APPL_TXT";
export const RCMD_PIN_THRD_CMPND_WT = "RCMD_PIN_THRD_CMPND_WT";
export const RCMD_BOX_CPL_THRD_CMPND_WT = "RCMD_BOX_CPL_THRD_CMPND_WT";
export const PIN_COAT_TYPE_NM = "PIN_COAT_TYPE_NM";
export const BOX_CPL_COAT_TYPE_NM = "BOX_CPL_COAT_TYPE_NM";
export const RCMD_MIN_MU_TRQ = "RCMD_MIN_MU_TRQ";
export const RCMD_MAX_MU_TRQ = "RCMD_MAX_MU_TRQ";
export const RCDM_OPT_MU_TRQ = "RCMD_OPT_MU_TRQ";
export const RCMD_MIN_SHLDR_TRQ = "RCMD_MIN_SHLDR_TRQ";
export const RCMD_MAX_SHLDR_TRQ = "RCMD_MAX_SHLDR_TRQ";
export const YLD_TRQ = "YLD_TRQ";
export const BOX_CPL_DRW_NBR = "BOX_CPL_DRW_NBR";
export const PIN_DRW_NBR = "PIN_DRW_NBR";
export const THRD_PROF_DRW_NBR = "THRD_PROF_DRW_NBR";
export const DTL_DRW_NBR = "DTL_DRW_NBR";
export const TEST_SMPL_TXT = "TEST_SMPL_TXT";
export const TEST_CMNT_TXT = "TEST_CMNT_TXT";
export const MB_CYC_CNT = "MB_CYC_CNT";
export const INTRNL_QNCH_TEST_FLG = "INTRNL_QNCH_TEST_FLG";
export const API_PRSR_RTNG_FLG = "API_PRSR_RTNG_FLG";
export const SEAL_TEST_FLG = "SEAL_TEST_FLG";
export const EXTDN_SEAL_TEST_FLG = "EXTDN_SEAL_TEST_FLG";
export const EXT_PRSR_TEST_FLG = "EXT_PRSR_TEST_FLG";
export const THRM_CYC_TEST_FLG = "THRM_CYC_TEST_FLG";
export const STRUCT_TEST_FLG = "STRUCT_TEST_FLG";
export const RPT_ID = "RPT_ID";
export const MOD_UID = "MOD_UID";
export const CHNG_DATE = "CHNG_DATE";
export const FEA_FLG = "FEA_FLG";
export const PRDCTLN_FLG = "PRDCTLN_FLG";
export const FULLEVAL_FLG = "FULLEVAL_FLG";
export const ACTIVE_FLG = "ACTIVE_FLG";
export const FLD_TRQ = "FLD_TRQ";
export const DOC_LINK = "DOC_LINK";
export const USE_DFLT_FLDR_FLG = "USE_DFLT_FLDR_FLG";

export const Fatigue_Evaluation = "Fatigue_Evaluation";
export const Limited_Sealability_Envelope = "Limited_Sealability_Envelope";
export const Bleed_Through = "Bleed_Through";
export const Unconventional = "Unconventional";
export const High_Torque = "High_Torque";
// export const Multi_Step_Thread = "MULT_STEP_THRD_FLG";

// export const Pipe_Critical_Cross_Section = "PIPE_CRTC_XSA";
// export const Variable_Length_box = "VAR_LNG_BOX_CPL_FLG";
// export const Box_Critical_Cross_Section = "BOX_CPL_CRTC_XSA";
// export const Pin_Critical_Cross_Section = "PIN_CRTC_XSA";
// export const Thread_Geometry_Type = "THRD_GEOM_TYPE_NM";
// export const Thread_Type = "THRD_TYPE_NM";
// export const Connection_Analysis_Date = "CNCT_ANAL_DATE";
// export const Derived_Envelope = "DRVD_ENVLP_FLG";
// export const Tubing_Qualification = "TBG_QLF_FLG";
export const Temperature_Rating = "TEMP_RTNG";
export const MB_Cycles = "MB_CYC_CNT";
// export const Internal_Quench_Test = "INTRNL_QNCH_TEST_FLG";
export const API_Pressure_Rating = "Internal_Quench_Test";
// export const External_Pressure_Test = "EXT_PRSR_TEST_FLG";
// export const Thermal_Cycle_Test = "THRM_CYC_TEST_FLG";
// export const Structural_Test = "STRUCT_TEST_FLG";

// export const Pin_Thread_Compound_Weight = "RCMD_PIN_THRD_CMPND_WT";
// export const Box_Thread_Compound_Weight = "RCMD_BOX_CPL_THRD_CMPND_WT";

// export const Additional_Qualification_Comment = "TEST_SMPL_TXT";

export const Administrative_Comments = "Administrative_Comments";
// export const Seal_Shoulder_Information = "Seal and Shoulder Information";

export const Seal_Type_Name = "Seal Type Name";
export const Seal_Location_Name = "Seal Location Name";
export const Shoulder_Location_Name = "Shoulder Location Name";
export const Modified_By = "Modified By";
export const Change = "Change ";

export const LIQUID = 'liquid';
export const GAS = 'gas';
export const STRUCTURAL = 'structural';

export enum DropdownTypeAdditionalDetailSectionControlNames {
    Fatigue_Evaluation = "Fatigue_Evaluation",
    Limited_Sealability_Envelope = "Limited_Sealability_Envelope",
    Bleed_Through = "Bleed_Through",
    Unconventional = "Unconventional",
    High_Torque = "High_Torque",
    API_PRSR_RTNG_FLG = "API_PRSR_RTNG_FLG",
    EXTDN_SEAL_TEST_FLG = "EXTDN_SEAL_TEST_FLG",
    SEAL_TEST_FLG = "SEAL_TEST_FLG",
    FEA_FLG = "FEA_FLG",
    FULLEVAL_FLG = "FULLEVAL_FLG",
    FLD_TRQ = "FLD_TRQ",
    PRDCTLN_FLG = "PRDCTLN_FLG"
}

export const Yes = 'Y';
export const No = 'N';

export enum DropDownYesNoOptions {
    Yes = 'Y',
    No = 'N'
}

export const DescriptonDetailControls: IDetailSectionControl[] = [
    // { controlName: CNCT_ID, defaultValue: '' },
    { controlName: Administrative_Comments, defaultValue: '' },
    { controlName: DATA_SRC_NM, defaultValue: '', isRequired: true },
    { controlName: NOM_PIPE_OD_DIAM, defaultValue: '', isRequired: true },
    { controlName: NOM_PIPE_WT, defaultValue: '' },
    { controlName: NOM_PIPE_WALL_THCK, defaultValue: '', isRequired: true },
    { controlName: NOM_PIPE_YLD_STRGTH, defaultValue: '' },
    { controlName: CRA_FLG, defaultValue: '' },
    { controlName: PIPE_TENS_RTNG, defaultValue: '' },
    { controlName: PIPE_CMPRS_RTNG, defaultValue: '' },
    { controlName: PIPE_INTRNL_PRSR_RTNG, defaultValue: '' },
    { controlName: PIPE_EXT_PRSR_RTNG, defaultValue: '' },
    { controlName: PIPE_CRTC_XSA, defaultValue: '' },
    { controlName: CNCT_ANAL_DATE, defaultValue: '', isRequired: true },
    { controlName: DRVD_ENVLP_FLG, defaultValue: '' },
    { controlName: MANUF_NM, defaultValue: '', isRequired: true },
    { controlName: CNCT_NM, defaultValue: '', isRequired: true },
    { controlName: CNCT_GEOM_TYPE_NM, defaultValue: '', isRequired: true },
    { controlName: FULL_CNCT_GRD_ID, defaultValue: '', isRequired: true },
    { controlName: TBG_QLF_FLG, defaultValue: '', isRequired: true },
    { controlName: NOM_BOX_CPL_OD, defaultValue: '' },
    { controlName: NOM_BOX_CPL_LNG, defaultValue: '' },
    // { controlName: VAR_LNG_BOX_CPL_FLG, defaultValue: '' },
    { controlName: MU_LOSS_LEN, defaultValue: '' },
    { controlName: CNCT_TNSN_RTNG, defaultValue: '' },
    { controlName: CNCT_CMPRS_RTNG, defaultValue: '' },
    { controlName: CNCT_INTRNL_PRSR_RTNG, defaultValue: '' },
    { controlName: CNCT_EXT_PRSR_RTNG, defaultValue: '' },
    { controlName: BOX_CPL_CRTC_XSA, defaultValue: '', isRequired: true },
    { controlName: PIN_CRTC_XSA, defaultValue: '', isRequired: true },
    { controlName: TEMP_RTNG, defaultValue: '' },
    { controlName: NOM_TNSL_EFC_PCT, defaultValue: '', isRequired: true },
    { controlName: NOM_CMPRS_EFC_PCT, defaultValue: '', isRequired: true },
    { controlName: NOM_INTRNL_PRSR_EFC_PCT, defaultValue: '', isRequired: true },
    { controlName: NOM_EXT_PRSR_EFC_PCT, defaultValue: '', isRequired: true },
    { controlName: THRD_GEOM_TYPE_NM, defaultValue: '' },
    { controlName: THRD_TYPE_NM, defaultValue: '' },
    { controlName: MULT_STEP_THRD_FLG, defaultValue: '' },
    { controlName: THRD_CMPND_APPL_TXT, defaultValue: '' },
    { controlName: RCMD_PIN_THRD_CMPND_WT, defaultValue: '' },
    { controlName: RCMD_BOX_CPL_THRD_CMPND_WT, defaultValue: '' },
    { controlName: PIN_COAT_TYPE_NM, defaultValue: '' },
    { controlName: BOX_CPL_COAT_TYPE_NM, defaultValue: '' },
    { controlName: RCMD_MIN_MU_TRQ, defaultValue: '' },
    { controlName: RCMD_MAX_MU_TRQ, defaultValue: '' },
    { controlName: RCDM_OPT_MU_TRQ, defaultValue: '' },
    { controlName: RCMD_MIN_SHLDR_TRQ, defaultValue: '' },
    { controlName: RCMD_MAX_SHLDR_TRQ, defaultValue: '' },
    { controlName: YLD_TRQ, defaultValue: '' },
    { controlName: BOX_CPL_DRW_NBR, defaultValue: '' },
    { controlName: PIN_DRW_NBR, defaultValue: '' },
    { controlName: THRD_PROF_DRW_NBR, defaultValue: '' },
    { controlName: DTL_DRW_NBR, defaultValue: '' },
    { controlName: TEST_SMPL_TXT, defaultValue: '' },
    { controlName: TEST_CMNT_TXT, defaultValue: '' },
    { controlName: MB_CYC_CNT, defaultValue: '' },
    { controlName: INTRNL_QNCH_TEST_FLG, defaultValue: '' },
    { controlName: EXT_PRSR_TEST_FLG, defaultValue: '' },
    { controlName: THRM_CYC_TEST_FLG, defaultValue: '' },
    { controlName: STRUCT_TEST_FLG, defaultValue: '' },
    { controlName: RPT_ID, defaultValue: '' },
    { controlName: MOD_UID, defaultValue: '' },
    { controlName: CHNG_DATE, defaultValue: '' },
    { controlName: FEA_FLG, defaultValue: DropDownYesNoOptions.No },
    { controlName: PRDCTLN_FLG, defaultValue: DropDownYesNoOptions.No },
    { controlName: FULLEVAL_FLG, defaultValue: DropDownYesNoOptions.No },
    { controlName: ACTIVE_FLG, defaultValue: DropDownYesNoOptions.Yes },
    { controlName: FLD_TRQ, defaultValue: '' },
    { controlName: DOC_LINK, defaultValue: '' },
    { controlName: USE_DFLT_FLDR_FLG, defaultValue: DropDownYesNoOptions.Yes },
    { controlName: Fatigue_Evaluation, defaultValue: DropDownYesNoOptions.No },
    { controlName: Limited_Sealability_Envelope, defaultValue: DropDownYesNoOptions.No },
    { controlName: Bleed_Through, defaultValue: DropDownYesNoOptions.No },
    { controlName: Unconventional, defaultValue: DropDownYesNoOptions.No },
    { controlName: High_Torque, defaultValue: DropDownYesNoOptions.No },
    { controlName: API_PRSR_RTNG_FLG, defaultValue: '' },
    { controlName: SEAL_TEST_FLG, defaultValue: '' },
    { controlName: EXTDN_SEAL_TEST_FLG, defaultValue: '' },
    { controlName: DATA_SRC_NM, defaultValue: '' },
    { controlName: CRA_FLG, defaultValue: '', isRequired: true },
    { controlName: Temperature_Rating, defaultValue: '' },
    // { controlName: Additional_Qualification_Comment, defaultValue: '' },
    { controlName: SEAL_TYPE_NM, defaultValue: '', isRequired: true },
    { controlName: SEAL_LOC_NM, defaultValue: '', isRequired: true },
    { controlName: SHLDR_LOC_NM, defaultValue: '', isRequired: true },
    { controlName: SealShoulderInformation_ChangeDate, defaultValue: '' },
    { controlName: SealShoulderInformation_ModifiedBy, defaultValue: '' },
];



export const ConnectionDescription = {
    e_CNCT_ID: CNCT_ID,
    e_USE_DFLT_FLDR_FLG: USE_DFLT_FLDR_FLG,
    e_DATA_SRC_NM: DATA_SRC_NM,
    e_NOM_PIPE_OD_DIAM: NOM_PIPE_OD_DIAM,
    e_NOM_PIPE_WT: NOM_PIPE_WT,
    e_NOM_PIPE_WALL_THCK: NOM_PIPE_WALL_THCK,
    e_NOM_PIPE_YLD_STRGTH: NOM_PIPE_YLD_STRGTH,
    e_CRA_FLG: CRA_FLG,
    e_PIPE_TENS_RTNG: PIPE_TENS_RTNG,
    e_PIPE_CMPRS_RTNG: PIPE_CMPRS_RTNG,
    e_PIPE_INTRNL_PRSR_RTNG: PIPE_INTRNL_PRSR_RTNG,
    e_PIPE_EXT_PRSR_RTNG: PIPE_EXT_PRSR_RTNG,
    e_PIPE_CRTC_XSA: PIPE_CRTC_XSA,
    e_CNCT_ANAL_DATE: CNCT_ANAL_DATE,
    e_DRVD_ENVLP_FLG: DRVD_ENVLP_FLG,
    e_MANUF_NM: MANUF_NM,
    e_CNCT_NM: CNCT_NM,
    e_CNCT_GEOM_TYPE_NM: CNCT_GEOM_TYPE_NM,
    e_FULL_CNCT_GRD_ID: FULL_CNCT_GRD_ID,
    e_TBG_QLF_FLG: TBG_QLF_FLG,
    e_NOM_BOX_CPL_OD: NOM_BOX_CPL_OD,
    e_NOM_BOX_CPL_LNG: NOM_BOX_CPL_LNG,
    e_VAR_LNG_BOX_CPL_FLG: VAR_LNG_BOX_CPL_FLG,
    e_MU_LOSS_LEN: MU_LOSS_LEN,
    e_CNCT_TNSN_RTNG: CNCT_TNSN_RTNG,
    e_CNCT_CMPRS_RTNG: CNCT_CMPRS_RTNG,
    e_CNCT_INTRNL_PRSR_RTNG: CNCT_INTRNL_PRSR_RTNG,
    e_CNCT_EXT_PRSR_RTNG: CNCT_EXT_PRSR_RTNG,
    e_BOX_CPL_CRTC_XSA: BOX_CPL_CRTC_XSA,
    e_PIN_CRTC_XSA: PIN_CRTC_XSA,
    e_TEMP_RTNG: TEMP_RTNG,
    e_NOM_TNSL_EFC_PCT: NOM_TNSL_EFC_PCT,
    e_NOM_CMPRS_EFC_PCT: NOM_CMPRS_EFC_PCT,
    e_NOM_INTRNL_PRSR_EFC_PCT: NOM_INTRNL_PRSR_EFC_PCT,
    e_NOM_EXT_PRSR_EFC_PCT: NOM_EXT_PRSR_EFC_PCT,
    e_THRD_GEOM_TYPE_NM: THRD_GEOM_TYPE_NM,
    e_THRD_TYPE_NM: THRD_TYPE_NM,
    e_MULT_STEP_THRD_FLG: MULT_STEP_THRD_FLG,
    e_THRD_CMPND_APPL_TXT: THRD_CMPND_APPL_TXT,
    e_RCMD_PIN_THRD_CMPND_WT: RCMD_PIN_THRD_CMPND_WT,
    e_RCMD_BOX_CPL_THRD_CMPND_WT: RCMD_BOX_CPL_THRD_CMPND_WT,
    e_PIN_COAT_TYPE_NM: PIN_COAT_TYPE_NM,
    e_BOX_CPL_COAT_TYPE_NM: BOX_CPL_COAT_TYPE_NM,
    e_RCMD_MIN_MU_TRQ: RCMD_MIN_MU_TRQ,
    e_RCMD_MAX_MU_TRQ: RCMD_MAX_MU_TRQ,
    e_RCDM_OPT_MU_TRQ: RCDM_OPT_MU_TRQ,
    e_RCMD_MIN_SHLDR_TRQ: RCMD_MIN_SHLDR_TRQ,
    e_RCMD_MAX_SHLDR_TRQ: RCMD_MAX_SHLDR_TRQ,
    e_YLD_TRQ: YLD_TRQ,
    e_BOX_CPL_DRW_NBR: BOX_CPL_DRW_NBR,
    e_PIN_DRW_NBR: PIN_DRW_NBR,
    e_THRD_PROF_DRW_NBR: THRD_PROF_DRW_NBR,
    e_DTL_DRW_NBR: DTL_DRW_NBR,
    e_TEST_SMPL_TXT: TEST_SMPL_TXT,
    e_TEST_CMNT_TXT: TEST_CMNT_TXT,
    e_MB_CYC_CNT: MB_CYC_CNT,
    e_INTRNL_QNCH_TEST_FLG: INTRNL_QNCH_TEST_FLG,
    e_API_PRSR_RTNG_FLG: API_PRSR_RTNG_FLG,
    e_SEAL_TEST_FLG: SEAL_TEST_FLG,
    e_EXTDN_SEAL_TEST_FLG: EXTDN_SEAL_TEST_FLG,
    e_EXT_PRSR_TEST_FLG: EXT_PRSR_TEST_FLG,
    e_THRM_CYC_TEST_FLG: THRM_CYC_TEST_FLG,
    e_STRUCT_TEST_FLG: STRUCT_TEST_FLG,
    e_RPT_ID: RPT_ID,
    e_MOD_UID: MOD_UID,
    e_CHNG_DATE: CHNG_DATE,
    e_FEA_FLG: FEA_FLG,
    e_PRDCTLN_FLG: PRDCTLN_FLG,
    e_FULLEVAL_FLG: FULLEVAL_FLG,
    e_ACTIVE_FLG: ACTIVE_FLG,
    e_FLD_TRQ: FLD_TRQ,
    e_DOC_LINK: DOC_LINK,
    // e_Pipe_Critical_Cross_Section: Pipe_Critical_Cross_Section,
    // e_Variable_Length_box: Variable_Length_box,
    // e_Box_Critical_Cross_Section: Box_Critical_Cross_Section,
    // e_Pin_Critical_Cross_Section: Pin_Critical_Cross_Section,
    // e_Pin_Thread_Compound_Weight: Pin_Thread_Compound_Weight,
    // e_Thread_Type: Thread_Type,
    // e_Connection_Analysis_Date: Connection_Analysis_Date,
    // e_Derived_Envelope: Derived_Envelope,
    // e_Tubing_Qualification: Tubing_Qualification,
    // e_Internal_Quench_Test: Internal_Quench_Test,
    // e_External_Seal_Test: External_Seal_Test,
    // e_External_Pressure_Test: External_Pressure_Test,
    // e_Thermal_Cycle_Test: Thermal_Cycle_Test,
    // e_Structural_Test: Structural_Test,
    // e_Box_Thread_Compound_Weight: Box_Thread_Compound_Weight,
    // e_Additional_Qualification_Comment: Additional_Qualification_Comment,
    // e_Thread_Geometry_Type: Thread_Geometry_Type,
    // e_Multi_Step_Thread: Multi_Step_Thread,
    e_Administrative_Comments: Administrative_Comments,
    e_Seal_Type_Name: SEAL_TYPE_NM,
    e_Seal_Location_Name: SEAL_LOC_NM,
    e_Shoulder_Location_Name: SHLDR_LOC_NM,
    e_SealShoulderInformation_ModifiedBy: SealShoulderInformation_ModifiedBy,
    e_SealShoulderInformation_ChangeDate: SealShoulderInformation_ChangeDate
}




export enum CSE_FILE_SHEET_NAME {
    CNCT_DESC = 'CNCT_DESC',
    CNCT_PRFM = 'CNCT_PRFM',
    CNCT_SEAL_TYPE = 'CNCT_SEAL_TYPE',
    CNCT_SEAL_LOC = 'CNCT_SEAL_LOC',
    CNCT_SHLDR_LOC = 'CNCT_SHLDR_LOC'
}

export enum DeleteConnection {
    deleteTitle = "Delete",
    deleteMessage = "Do you want to delete this Connection?",
    DeleteYes = "Yes",
    DeleteNo = "No"
}
export const variableSpecifications: { [key: string]: string } = {
  NOM_PIPE_OD_DIAM: "Size (OD)",
  NOM_PIPE_WT: "Weight",
  NOM_PIPE_WALL_THCK: "Wall Thickness",
  NOM_PIPE_YLD_STRGTH: "Yield",
  PIPE_TENS_RTNG: "Tension - Pipe Body",
  PIPE_CMPRS_RTNG: "Compression - Pipe Body",
  PIPE_INTRNL_PRSR_RTNG: "Internal Pressure - Pipe Body",
  PIPE_EXT_PRSR_RTNG: "External Pressure - Pipe Body",
  PIPE_CRTC_XSA: "Pipe Critical Cross-Section",
  NOM_BOX_CPL_OD: "Coupling/Box OD",
  NOM_BOX_CPL_LNG: "Coupling/Box Length",
  MU_LOSS_LEN: "Make-Up Loss",
  CNCT_TNSN_RTNG: "Tension - Connection",
  CNCT_CMPRS_RTNG: "Compression - Connection",
  CNCT_INTRNL_PRSR_RTNG: "Internal Pressure - Connection",
  CNCT_EXT_PRSR_RTNG: "External Pressure - Connection",
  BOX_CPL_CRTC_XSA: "Box Critical Cross-Section",
  PIN_CRTC_XSA: "Pin Critical Cross-Section",
  TEMP_RTNG: "Temperature Rating",
  NOM_TNSL_EFC_PCT: "Tension Efficiency",
  NOM_CMPRS_EFC_PCT: "Compression - Efficiency",
  NOM_INTRNL_PRSR_EFC_PCT: "Internal Pressure - Efficiency",
  NOM_EXT_PRSR_EFC_PCT: "External Pressure - Efficiency",
  RCMD_PIN_THRD_CMPND_WT: "Pin Thread Compound Weight",
  RCMD_BOX_CPL_THRD_CMPND_WT: "Box Thread Compound Weight",
  RCMD_MIN_MU_TRQ: "Final Min",
  RCMD_MAX_MU_TRQ: "Final Max",
  RCMD_OPT_MU_TRQ: "Final Optimum",
  RCMD_MIN_SHLDR_TRQ: "Shoulder Min",
  RCMD_MAX_SHLDR_TRQ: "Shoulder Max",
  YLD_TRQ: "Yield Torque",
  MB_CYC_CNT: "M&B Cycles",
  CNCT_ANAL_DATE: "Connection Analysis Date",
  NET_PRSR: "NET PRSR",
  AXIAL_LOAD: "AXIAL LOADS",
  PLOT_SEQ_NBR: "PLOT SEQNBR"
};



