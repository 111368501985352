import { ICandidateConnectionResult, IConnectionPerformance, ITestSpecimen } from "./interfaces/iCandidateConnectionResult.interface";

export class CandidateConnectionResult implements ICandidateConnectionResult {
  isHighTorque: string;
  threadCompound: string;
  document: string;
  manufacturer: string;
  connectionName: string;
  connectionTypeString: string;
  weight: number;
  gradesReportString: string;
  gasEnvelopePresence: string;
  connectionId: string;
  fullyEvaluated: string;
  active: string;
  od: number;
  yield: number;
  wallThickness: number;
  odUOM: string;
  weightUOM: string;
  productLine: string;
  manufacturerLogo: string;
  testSpecimens?: ITestSpecimen[] = [];
  connectionPerformances?: IConnectionPerformance[] = [];
  lastUpdated?: string;
  material?: string;
  box?: string;
  pin?: string;
  thread?: string;
  details?: string;
  couplingBoxOD?: number;
  couplingBoxLength?: number;
  makeupLoss?: number;
  connectionUse?: string;
  threadCompoundComment?: string;
  connectionDocuments?: string;
  pinsurfacefinish?: string;
  couplingsurfacefinish?: string;
  evaluationStatus?: string;
  evaluationMethod?: string;
  finalMin?: string;
  finalMax?: string;
  finalOptimum?: string;
  shoulderMin?: string;
  shoulderMax?: string;
  yieldMin?: string;
  tensionPipeBody?: number;
  tensionConnection?: number;
  tensionEfficiency?: number;
  compressionPipebody?: number;
  compressionConnection?: number;
  compressionEfficiency?: number;
  internalPressurePipebody?: number;
  internalPressureConnection?: number;
  internalPressureEfficiency?: number;
  externalPressurePipebody?: number;
  externalPressureConnection?: number;
  externalPressureEfficiency?: number;
  documentLink?: string;
  sealTypeMetal?: string;
  sealTypeTaperedThread?: string;
  sealTypeRing?: string;
  sealLocPin?: string;
  sealLocMid?: string;
  sealLocBox?: string;
  shoulderLocNone?: string;
  shoulderLocPin?: string;
  shoulderLocMid?: string;
  threadType?: string;
  multiStep?: string;
  shoulderLocBox?: string;
  threadCompoundBox?: string;
  threadCompoundPin?: string;
  threadGeometry?: string;
  criticalCrossSectAreaPipeBody?: number;
  criticalCrossSectAreaCouplingBox?: number;
  criticalCrossSectAreaPin?: number;
  testComments?: string;
  yieldUOM?: string;
  torqueUOM?: string;
}

export class TestSpecimen implements ITestSpecimen {
  testSpecimenId: string;
  connectionId: string;
  testProcedure: string;
  specimenType: string;
  materialGrade: string;
  testType: string;
  testDate: Date;
  testFacility: string;
  testReportLocation: string;
  comments: string;
  modUser: string;
  changeDate: string
}
