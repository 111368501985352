<app-alert-error></app-alert-error>
<div class="selfcontainer">
  <div>
    <div class="titleLabel">
      VTable Editor
    </div>
    <div class="em-c-field" style="margin-top:20px;">
      VTable
      <select class="selectStyle" (change)="changedata($event)">
        <option selected disabled>Choose VTable Name</option>
        <option *ngFor="let item of parameterlist" [value]="item">
          {{item}}
        </option>
      </select>
    </div>
    <div class="em-c-field" *ngIf="showTable">
      <div class="em-c-field__body">
        <div class="scrollbar">
          <div class="fpo-block">
            <app-display-table [tableHeaderList]="tableHeader" [tableRowList]="tableData" [addRow]="useAddRow" [isTableMandatory]="false" (deleteRowEvent)="deleteTableData($event)" (rowUpdated)="addEventCall()" [showcopyfrom]="false" [isvTable]="true"></app-display-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-save-discard *ngIf="showTable" (Saved)="saveVTableData()" (Discarded)="discarded()" [DisableDiscard]="!isDataModified" [SaveDiscard]="!isDataModified"></app-save-discard>
</div>

<div *ngIf="ConfirmationModalOpen" class="em-js" id="div_modal_start">
  <div class="em-c-modal em-js-modal" id="modal">
    <div class="em-c-modal__window em-js-modal-window">
      <div class="em-c-modal__header header-color">
        <h3 class="em-c-modal__title h3width">Delete record?</h3>
        <button (click)="closeModal()" class="model_close-button">Close</button>
      </div>
      <div class="border_header_bottom">
      </div>
      <p class="p-font">Do you want to delete this record?</p>
      <div class="border__body">
        <button class="em-c-btn em-c-btn--primary  em-js-btn-selectable" (click)="deleterecord($event)">Confirm</button>
        <button style="left: 25px;" class="em-c-btn em-c-btn--secondary em-js-modal-cancel-trigger cancel-button"
                (click)="closeModal()">
          Cancel
        </button>
      </div>
    </div>
  </div>

</div>
