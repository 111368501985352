import { Component, OnInit, Input } from '@angular/core';
import { ITableHeader } from '../../../../common/models/components/iTableHeader';
import { ICandidateConnectionResult } from '../../../../common/models/responses/interfaces/iCandidateConnectionResult.interface';
import { CandidateConnectionResult } from '../../../../common/models/responses/candidateConnectionResult';
import { MsalService } from '@azure/msal-angular';
import { TabStorageService } from '../../../../service/tcdb/tabstorage.service';

@Component({
  selector: 'app-details-tab-item',
  templateUrl: './details-tab-item.component.html',
  styleUrls: ['./details-tab-item.component.css']
})
export class DetailsTabItemComponent{
  @Input() detailsList: ICandidateConnectionResult = new CandidateConnectionResult();
  constructor(public tabStorageService: TabStorageService) { 
  }
  
}
