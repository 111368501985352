<div class="em-dc-table-object em-c-table--striped em-dc-bottom_margin_zero em-dc-table_striped">
  <div class="em-c-table-object__body">
    <div class="em-c-table-object__body-inner">
      <table class="em-c-table em-dc-table em-u-margin-bottom-none">
        <thead class="em-c-table__header em-dc-table_header em-dc-header_default">
          <tr class="em-c-table__header-row em-dc-table_header_row em-dc-no-wrap">
            <th scope="col" class="em-c-table__header-cell em-dc-table_header-cell "
              *ngFor="let header of tableHeaderList">
              <div *ngIf="header.isSortable" class="sortable-title">
                <a href="javascript:void(0);" (click)="onSortByChange(header)"
                  data-testid="sortTable">{{header.name}}</a>

                <a href="javascript:void(0);" *ngIf="sortedByHeader.name === header.name" class="sortable-arrow"
                  (click)="onSortByChange(header)">
                  <svg class="em-c-icon em-c-icon--medium">
                    <use *ngIf="header.isAscending" xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="../../../../../assets/vendor/unity/images/24/em-icons.svg#caret-down"></use>
                    <use *ngIf="!header.isAscending" xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="../../../../../assets/vendor/unity/images/24/em-icons.svg#caret-up"></use>
                  </svg>
                </a>
              </div>

              <div *ngIf="!header.isSortable">{{header.name}} <span *ngIf="header.isRequired" class="redast">*</span>
              </div>

            </th>
            <th width="5%"></th>
          </tr>
        </thead>
        <tbody class="em-c-table__body em-dc-table_tbody ">
          <tr>
            <td [attr.colspan]="tableHeaderList.length">
              <a href="javascript:void(0);" (click)="addRowVisible(target)" data-testid="addrow"
                *ngIf="addRow && !showAddRowInput">Add row</a>
              <a style="padding-left: 15px;" href="javascript:void(0);" (click)="showCopy()" *ngIf="showcopyfrom">Copy
                from Excel</a>
            </td>
          </tr>
          <tr *ngIf="isToShowCopy">
            <td colspan="2">
              <div style="border:solid;height:100px" (paste)="copyClipboard($event)">
                paste area
              </div>
            </td>
          </tr>
          <tr class="em-c-table__row em-dc-table_row em-dc-no-wrap em-dc-table em-u-margin-bottom-none"
            *ngFor="let tableRow of tableRowList; let i = index">
            <td class="em-c-table__cell em-js-cell em-js-cell-editable em-dc-table-spacing em-dc-no-wrap"
              *ngFor="let tdColumn of tableRow.tdColumn">
              <div style="width:100%; min-width: 50px; min-height: 30px"
                *ngIf="!tdColumn.editable && tableRow.editable && !isvTable"
                (dblclick)="updateEditableColumn(tdColumn)">{{tdColumn.value}}</div>

              <span *ngIf="(!tdColumn.editable && !tableRow.editable) || isvTable">{{tdColumn.value}}</span>

              <div *ngIf="tdColumn.editable">
                <ng-container [ngSwitch]="getInputType(tdColumn)">
                  <input *ngSwitchCase="'number'" [id]="getElementId(tdColumn.headerName)" value="{{tdColumn.value}}" type="text"
                    (focusout)="updateRow($event.target.value, tdColumn, i)"
                    data-testid="inputrows"  appNumericInput/>
                  <input *ngSwitchCase="'date'" [id]="getElementId(tdColumn.headerName)" type="date"
                    (focusout)="updateRow($event.target.value, tdColumn, i)"
                    data-testid="inputrows" />
                  <select *ngSwitchCase="'dropdown'" class="drop-down" [id]="getElementId(tdColumn.headerName)"
                    (change)="updateRow($event.target.value.split(':')[1].trim(), tdColumn, i)"
                    data-testid="inputrows" [ngModel]="tdColumn.value">
                    <option *ngFor="let option of tdColumn.options" [ngValue]="option.value">{{ option.label }}</option>
                  </select>
                  <input *ngSwitchDefault [id]="getElementId(tdColumn.headerName)" type="text" value="{{tdColumn.value}}"
                    (focusout)="updateRow($event.target.value, tdColumn, i)"
                    data-testid="inputrows" />
                </ng-container>
                <div class="em-c-field__note" *ngIf="tdColumn.errorMessage && isTableMandatory">
                  {{tdColumn.errorMessage}}</div>
              </div>



            </td>
            <td class="delete-icon" *ngIf="tableRow.editable && isTableColumnsEditable">
              <a href="javascript:void(0);" class="delete-icon" (click)="deleteRow(i)" data-testid="deleterow">
                <svg class="em-c-icon em-c-icon--medium">
                  <use xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="../../../../../assets/vendor/unity/images/24/em-icons.svg#x-filled"></use>
                </svg>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot #target></tfoot>
      </table>
    </div>
  </div>
</div>
